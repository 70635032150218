	/*
  	Flaticon icon font: Flaticon
  	Creation date: 22/11/2017 11:12
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype");
       /*url("../fonts/Flaticon.svg#Flaticon") format("svg");*/
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    /*src: url("./Flaticon.svg#Flaticon") format("svg");*/
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-headphones:before { content: "\f100"; }
.flaticon-wallet:before { content: "\f101"; }
.flaticon-notification:before { content: "\f102"; }
.flaticon-shield:before { content: "\f103"; }
.flaticon-users:before { content: "\f104"; }
.flaticon-magnifying-glass:before { content: "\f105"; }
.flaticon-people:before { content: "\f106"; }
.flaticon-computer-screen:before { content: "\f107"; }
.flaticon-dots-menu:before { content: "\f108"; }