/*------------------------------------------------------------------

/*--------- Body Strat -------*/
body, html {
	font-family: 'Lato', sans-serif;
	font-size: 16px;
	line-height: 23px;
	color: #6f7e98;
	background:#13171e;
	overflow-x:hidden;
}
/*---------- Custom Css Start -------*/

.file-viewer-style{
    width: 100%;
    height: 1080px;
}
.pg-viewer-wrapper {
    overflow-y: hidden!important;
}
.pg-viewer-wrapper .document-container{
    /*width: 100%;*/
    height: 1080px;
    overflow-y: scroll;
}
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
}

.pagination button {
    margin: 0 8px;
    padding: 8px 16px;
    background-color: #f5f5f5;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.pagination button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
/*-- Preloader css start --*/
#preloader {
	background-color: #1a87c5;
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 2147483647;
	width: 100%;
	height: 100%;
}
#status {
    background-position: center center;
    background-repeat: no-repeat;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
}
#status img {
}
a {
	color: #6f7e98;
	text-decoration: none;
}
a:hover, a:focus {
	color: #707070;
	text-decoration: none;
	outline: none;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: 'Montserrat', sans-serif;
	line-height: 1.1;
	color: #222222;
	margin:0;
	padding:0;
}
input, select, button, textarea {
	outline: none;
}
input:focus, select:focus, button:focus, textarea:focus {
	outline: none;
	box-shadow:none;
}
ul{
	list-style:none;
	margin:0;
	padding:0;
}
/*-- Header Css Start --*/	
.gc_header_wrapper{
    float: left;
    width: 100%;
	text-align:center;
}
.gc_logo{
    float: left;
    width: auto;
}
.gc_logo img{
	display:inline-block;
}
.header-area{
	float:left;
	width:100%;
}

.header-area {
	padding-top: 15px;
    width: 100%;
    z-index: 9999;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}
.logo a {
    color: #fff;
    display: inline-block;
}
.logo a h2 {
    display: inline-block;
    margin: 0;
    font-weight: 500;
    text-transform: uppercase;
}
.logo a h2 span {
    background-color: #fff;
    color: #222;
    display: inline-block;
    padding: 5px 10px;
}
.jp_top_header_img_wrapper{
	float:left;
	width:100%;
	height:100%;
	border-top:3px solid #23c0e9;
	position:relative;
	background-color: hsla(200,40%,30%,.4);
	background-image: url('../assets/img/header_img.jpg');
	background-repeat: repeat-x;
	background-position: 
		0 20%,
		0 95%,
		0 0,
		0 100%,
		0 0;

	animation: 90s para infinite linear;
}

@keyframes para {
	100% {
		background-position: 
			-5000px 20%,
			-800px 95%,
			500px 0,
			1000px 100%,
			400px 0;
		}
}
.gc_main_menu_wrapper{
	float:left;
	width:100%;
	padding-top: 30px;
	border-bottom: 1px solid #ffffff21;
	position:relative;
}		
.jp_slide_img_overlay{
	position:absolute;
	top:0;
	right:0;
	left:0;
	bottom:0;
	background:rgba(14, 20, 39, 0.8313725490196079);
}
.menu-bar {
    cursor: pointer;
    position: relative;
    right: 15px;
    z-index: 9;
    height: 42px;
    display: block;
    float: right;
    margin-top: 15px;
}
.menu-bar span {
    background-color: #fff;
    display: block;
    height: 2px;
    margin-bottom: 6px;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    width: 30px;
}
.mainmenu {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    padding-right: 15px;
    position: absolute;
    right: -145px;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    visibility: hidden;
}
.mainmenu.menu-open {
    position: relative;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    right: 15px;
    visibility: visible;
}
.mainmenu ul li {
    display: inline-block;
    position: relative;
}
.mainmenu ul li a {
    color: #000000;
    display: block;
    font-size: 14px;
    letter-spacing: 1px;
    padding: 0px 20px 47px 10px;
	text-transform: capitalize;
	font-family: 'Montserrat', sans-serif;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    position: relative;
}
.gc_main_navigation{
	color:#ffffff !important;
}
.mainmenu ul li:hover .gc_main_navigation,
.mainmenu ul li.active > .gc_main_navigation {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	color:#ffffff !important;
}
.nav > li > a:focus,
.nav > li > a:hover {
    background-color: transparent;
}
.menu-bar:after,
.menu-bar:before {
    background-color: #fff;
    content: "";
    height: 2px;
    position: absolute;
    right: 0;
    top: 8px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    width: 30px;
}
.menu-bar.menu-close:after {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.menu-bar.menu-close:before {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.menu-bar.menu-close span {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}
/*************************
02.1 Submenu & Megamenu
*************************/

.mainmenu ul ul,
.mainmenu ul li .mega-menu {
    width: 220px;
    position: absolute;
    text-align: left;
    background-color: #0e1427;
    top: calc(100% + 30px);
    z-index: 9999999;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    visibility: hidden;
    -webkit-transform-origin: 0 0 0;
    transform-origin: 0 0 0;
}
.mainmenu > ul > li:last-child > ul {
    right: 0;
}
.mainmenu ul li:hover > ul,
.mainmenu ul li:hover > .mega-menu {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    visibility: visible;
    top: 100%;
	background:#0e142773;
	border-top: 2px solid #23c0e9;
}
.mainmenu ul ul li {
    display: block;
}
.mainmenu ul ul li a,
.mainmenu ul li.has-mega .mega-menu span a {
    color: #222;
    text-transform: capitalize;
    letter-spacing: 1px;
    padding: 8px 10px 8px 20px;
    border-bottom: 1px solid #ffffff52;
}
.mainmenu ul li.has-mega .mega-menu span a:hover{
	background-color: #00468c;
	color:#ffffff !important;
}
.mainmenu ul ul li:last-child > a {
    border-bottom: 0px;
}
.mainmenu ul ul li:hover > a {
    background-color: #23c0e9;
    padding-left: 23px;
	color:#ffffff !important;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.mainmenu ul ul li a:after {
    content: "";
}
.mainmenu ul ul li > ul {
    left: -100%;
    top: 50%;
    -webkit-transform-origin: 100% 50% 0;
    transform-origin: 100% 50% 0;
}
.mainmenu ul ul li:hover > ul {
    top: 20%;
}
.mainmenu ul li.has-mega {
    position: static;
}
.mainmenu ul li.has-mega .mega-menu {
    width: 100%;
    left: 0;
    padding: 20px 0px;
}
.mainmenu ul li.has-mega .mega-menu span {
    width: 25%;
    float: left;
    border-left: 1px solid #eee;
}
.mainmenu ul li.has-mega .mega-menu span a {
    padding-left: 20px;
    padding-right: 0px;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.mainmenu ul li.has-mega .mega-menu span a:last-child {
    border-bottom: 0px;
}
.mainmenu ul li.has-mega .mega-menu span a:hover {
	padding-left: 20px;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.mainmenu ul li span a.active {
    font-weight: 600;
}
.mainmenu ul ul li.active > a {
    background-color: #eee;
    font-weight: 600;
}
.mainmenu .gc_main_navigation.parent:after,
.mainmenu .gc_main_navigation.parent:before,
.mainmenu ul li.has-mega > a:before,
.mainmenu ul li.has-mega > a:after {
    content: "";
    width: 11px;
    height: 1px;
    background-color: #23c0e9 !important;
    top: 20px;
    position: absolute;
	left:0;
    right: 0;
	margin:0px auto;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}
.mainmenu .gc_main_navigation.parent:before,
.mainmenu ul li.has-mega a:before {
    width: 0px;
    top: 50%;
	left:-40px;
}
.mainmenu .gc_main_navigation.parent:hover:before,
.mainmenu ul li.has-mega:hover > a:before {
    width: 10px;
    top: 50%;
}
.mainmenu .gc_main_navigation.parent:after,
.mainmenu ul li.has-mega a:after {
    width: 0px;
    top: 50%;
	left:5px;
}
.mainmenu .gc_main_navigation.parent:hover:after,
.mainmenu ul li.has-mega:hover > a:after {
    width: 30px;
    top: 50%;
}
/*************************
02.2 Sticky menu
*************************/

.menu_fixed{
	position:fixed;
	background:#ffffff;
	-webkit-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -moz-box-shadow:  0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -o-box-shadow:  0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    -ms-box-shadow:  0px 1px 20px 0px rgba(0, 0, 0, 0.10);
    box-shadow:  0px 1px 20px 0px rgba(0, 0, 0, 0.10);
	z-index:1000;
	left:0;
	right:0;
}
.header-area.stick .logo a {
    color: #222;
    margin-top: 2px;
    position: relative;
    z-index: 9999999;
}
.header-area.stick .logo a h2 span {
    background-color: #222;
    color: #fff;
}
.header-area.stick .mainmenu ul li a,
.stick .mean-container a.meanmenu-reveal {
    color: #ffffff;
}

.header-area.stick .menu-bar:after,
.header-area.stick .menu-bar:before,
.header-area.stick .menu-bar span,
.stick .mainmenu ul li.parent:after,
.stick .mainmenu ul li.parent:before,
.stick .mainmenu ul li.has-mega > a:before,
.stick .mainmenu ul li.has-mega > a:after,
.stick .mean-container a.meanmenu-reveal span {
    background-color: #222;
}
/*************************
02.3 Hidden menu bar
*************************/

.hidden-menu-bar .mainmenu {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    visibility: visible;
    padding-right: 0;
    position: relative;
	z-index:10;
	float: right;
}
.mainmenu:after{
	content: '';
    border: 1px solid #ffffff21;
    width: 1px;
    height: 116px;
    position: absolute;
    top: -44px;
    right: -16px;
}
.float_left{
	float:left;
}
/*************************
02.4 Onepage side menu
*************************/

.left-side-wrapper {
    background-color: #222;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 15%;
    z-index: 99;
}
.left-side-wrapper .logo {
    text-align: center;
}
.left-side-wrapper .mainmenu ul li {
    display: block;
}
.left-side-wrapper .mainmenu ul li a {} .left-side-wrapper .mainmenu {
    margin-top: 50px;
    position: relative;
}
.left-side-wrapper .mainmenu ul li > a:after,
.left-side-wrapper .mainmenu ul li.active > a:after {
    bottom: -5px;
    left: 20px;
    right: auto;
}
.right-side-wrapper {
    margin-left: 15%;
    width: 85%;
}
.right-side-wrapper .container-fluid {
    padding: 0% 10%;
}
.right-side-wrapper .container-fluid .single-project-item {
    height: 370px;
}
.right-side-wrapper .container-fluid .single-project-item.large {
    height: 460px;
}
/*************************
02.5 Onepage Menu
*************************/

.mainmenu.one-page-menu ul li a {
    padding-right: 10px;
    padding-left: 10px;
}
.mainmenu.one-page-menu ul li:hover a:after,
.mainmenu.one-page-menu ul li.active a:after {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    width: 90%;
}
.mainmenu.one-page-menu ul li a:after {
    width: 0%;
    height: 1px;
    content: "";
    background-color: #fff;
    position: absolute;
    left: 5%;
    top: 50%;
    margin-top: -5px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}
.stick .mainmenu.one-page-menu ul li a:after {
    background-color: #222;
}
/*************************
02.6 Mobile Menu
*************************/

.slicknav_menu {
    display: none;
}
.gc_right_menu{
	float:right;
	width:auto;
    margin-top: -7px;
}
.gc_right_menu > ul{
	float:left;
	width:auto;
	margin:0px;
	padding:0px;
}
.gc_right_menu > ul > li{
	float:left;
	width:auto;
	list-style:none;
	margin-right:10px;
	position:relative;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.gc_right_menu ul > li:last-child{
	margin-right:0px;
}
.gc_right_menu ul > li .gc_btn{
	background-color:#00468c;
    border: 2px solid #00468c;
	color:#ffffff;
}
.gc_right_menu > ul > li .gc_btn:hover{
	background-color:#ffffff;
    border: 2px solid #00468c;
	color:#00468c;
}
.gc_right_menu > ul > li > svg{
	width: 36px;
    height: 28px;
    padding-top: 13px;
    cursor: pointer;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.gc_right_menu > ul > li svg:hover #share, .gc_right_menu > ul > li > svg:hover #search, .gc_right_menu > ul > li > svg:hover .menubar{
	fill:#23c0e9;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.gc_search_box{
    position: absolute;
    right: 0;
    top: -13px;
    padding: 0px;
    width: 350px;
    display: none;
    border-radius: 0px;
    -webkit-box-shadow: 0px 2px 7px -3px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 2px 7px -3px rgba(0, 0, 0, 0.5);
    -o-box-shadow: 0px 2px 7px -3px rgba(0, 0, 0, 0.5);
    -ms-box-shadow: 0px 2px 7px -3px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 5px 8px -5px rgba(0, 0, 0, 0.5);
    z-index: 1000;
}
.gc_search_box input{
    float: left;
    width: calc(100% - 60px);
    padding: 0px 10px;
    height: 60px;
    border: 1px solid #e1e1e1;
    border-right: 0px;
  -webkit-border-top-left-radius: 50px; 
  -moz-border-bottom-left-radius: 50px; 
   border-top-left-radius: 50px; 
   border-bottom-left-radius: 50px; 
}
.gc_search_box button{
    width: 60px;
    height: 60px;
	color:#ffffff;
    border: 1px solid #8bdaee;
    border-left: none;
    background-color: #8bdaee;
    text-align: center;
    padding: 0px;
	font-size:24px;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
	-webkit-border-top-right-radius: 50px; 
  -moz-border-bottom-right-radius: 50px; 
   border-top-right-radius: 50px; 
   border-bottom-right-radius: 50px;
}
.gc_search_box button:hover{
	color:#ffffff;
	background-color: #23c0e9;
	border-color:#23c0e9;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.menu_fixed{
	position: fixed;
	z-index:1000;
    top: 0;
    padding: 25px 0px 0px;
    background-color:#ffffff;
	-o-box-shadow:0px 0px 25px -10px;
	-ms-box-shadow:0px 0px 25px -10px;
	-moz-box-shadow:0px 0px 25px -10px;
	-webkit-box-shadow:0px 0px 25px -10px;
	box-shadow:0px 0px 25px -10px;
}
/*-- Button Css Start --*/
.gc_btn{
    display: inline-block;
	width:auto;
	height:50px;
	line-height:49px;
	font-size:16px;
	color:#111111;
	text-transform:uppercase;
    font-weight: normal;
	background-color: #ffffff;
	text-align:center;
	-webkit-border-radius:50px;
	-moz-border-radius:50px;
	border-radius:50px;
	border:2px solid #e1e1e1;
    padding: 0px 30px;
	font-family: 'Droid Serif', serif;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.gc_btn:hover{
	color:#ffffff;
	background-color:#e5ae49;
	border:2px solid #e5ae49;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.gc_main_navigation i{
	color:#23c0e9;
}
.jp_navi_right_btn_wrapper ul{
	float:right;
}
.jp_navi_right_btn_wrapper li{
	float:left;
	margin-left:20px;
}
.jp_navi_right_btn_wrapper li:first-child{
	margin-left:0;
}
.jp_navi_right_btn_wrapper li:first-child a{
	float:left;
	width:130px;
	height:50px;
	line-height:50px;
	color:#ffffff;
	border:1px solid #ffffff40;
	text-align:center;
	background:transparent;
	-webkit-border-radius:10px;
	-moz-border-radius:10px;
	border-radius:10px;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_navi_right_btn_wrapper li:first-child a:hover{
	background:#f36969;
	border:1px solid #f36969;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_navi_right_btn_wrapper li:last-child a, .jp_navi_right_btn_wrapper li:last-child button{
	float:left;
	width:130px;
	height:50px;
	line-height:50px;
	color:#ffffff;
	border:1px solid #f36969;
	background:#f36969;
	text-align:center;
	-webkit-border-radius:10px;
	-moz-border-radius:10px;
	border-radius:10px;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_navi_right_btn_wrapper li:last-child a:hover, button:hover{
	background:transparent;
	border:1px solid #ffffff40;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
/*-- Heading Css Start --*/
.gc_heading{
	float:left;
	width:100%;
	margin-bottom:60px;
	text-align:center;
	text-transform:uppercase;
}
.gc_heading h1{
	float:left;
	width:100%;
	margin:10px 0px;
	font-size:36px;
	color:#00468c;
	font-family: 'Lora', serif;
}
.gc_heading h2{
	float:left;
	width:100%;
	margin:0px;
	font-size:30px;
	color:#e5ae49;
	font-family: 'Great Vibes', cursive;
}
.jp_banner_heading_cont_wrapper{
	float:left;
	width:100%;
	padding-top:130px;
    position: relative;
}
.jp_job_heading h1{
	font-size:60px;
	color:#ffffff;
}
.jp_job_heading h1 span{
	color:#23c0e9;
	font-weight:bold;
}
.jp_job_heading p{
	font-size:20px;
	color:#ffffffa8;
	padding-top:10px;
}
.jp_header_form_wrapper{
	float:left;
	width:100%;
	background:#ffffff24;
    margin-top:40px;
    padding: 30px 20px;
}
.jp_header_form_wrapper input, .jp_form_exper_wrapper input{
	width:100%;
	height:50px;
	-webkit-border-radius:15px;
	-moz-border-radius:15px;
	border-radius:15px;
	border:transparent;
	padding-left:20px;
	padding-right: 20px;
}
.jp_form_location_wrapper select, .jp_form_exper_wrapper select{
	width:100%;
	height:50px;
	-webkit-border-radius:15px;
	-moz-border-radius:15px;
	border-radius:15px;
	border:transparent;
	padding-left:40px;
	-webkit-appearance: none;
    -moz-appearance: none;
	position:relative;
}
.jp_form_location_wrapper .second_icon, .jp_form_exper_wrapper .second_icon{
	margin-left:-30px;
	position: absolute;
    top: 19px;
}
.jp_form_location_wrapper .first_icon, .jp_form_exper_wrapper .first_icon{
	position:absolute;
	top:17px;
	font-size:20px;
	left:30px;
	z-index:1;
	color:#23c0e9;
}
.jp_form_btn_wrapper li a{
	float:left;
	width:100%;
	height:50px;
	-webkit-border-radius:15px;
	-moz-border-radius:15px;
	border-radius:15px;
	background:#23c0e9;
	color:#ffffff;
	line-height:50px;
	text-align:center;
	font-weight:bold;
	font-size:16px;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_form_btn_wrapper li a:hover{
	background:#ffffff;
	color:#000000;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_form_btn_wrapper li a i{
	padding-right:5px;
}
.jp_banner_main_jobs_wrapper{
	float:left;
	width:100%;
	margin-top:40px;
	padding-bottom:150px;
}
.jp_banner_main_jobs li{
	float:left;
	margin-left:20px;
}
.jp_banner_main_jobs li:first-child{
	margin-left:0;
	color:#ffffff;
}
.jp_banner_main_jobs li i{
	padding-right:5px;
	color:#23c0e9;
}
.jp_banner_main_jobs li a{
	color:#ffffffcf;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
	
}
.jp_banner_main_jobs li a:hover{
	color:#23c0e9;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_banner_jobs_categories_wrapper{
	float:left;
	width:100%;
	height:100%;
	background:#ffffff24;
	position:relative;
}
.jp_top_jobs_category_wrapper{
	float:left;
	width:16.5%;
	text-align:center;
	background:transparent;
	padding-top:30px;
	padding-bottom:30px;
	border-right:1px solid #ffffff21;
	 -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1); 
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1); 
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1);
    transition: all 200ms ease-in;
    transform: scale(1);   
}
.jp_job_cate_left_border{
	border-left:1px solid #ffffff21;
}
.jp_top_jobs_category_wrapper:hover{
	background:#23c0e9;
	box-shadow: 0px 0px 37px #00000091;
    z-index: 2;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1.5);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1.1);   
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1.1);
    transition: all 200ms ease-in;
    transform: scale(1.1);
}
.jp_top_jobs_category{
	display:inline-block;
}
.jp_top_jobs_category i{
	color:#23c0e9;
	font-size:25px;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_top_jobs_category_wrapper:hover .jp_top_jobs_category i{
	color:#ffffff;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_top_jobs_category h3{
	padding-top:15px;
	font-size:16px;
	font-weight:bold;
}
.jp_top_jobs_category h3 a{
	color:#ffffff;
}
.jp_top_jobs_category p{
	font-size:14px;
	color:#ffffffb8;
	padding-top:5px;
}
/*---------- header Css End -------*/
/*---------- jp tittle slider Wrapper Start -------*/
.jp_tittle_name_wrapper{
	float:left;
	width:15%;
	text-align:center;
	background:#23c0e9;
	padding-top: 35px;
    padding-bottom: 30px;
}
.jp_tittle_name{
	display:inline-block;
}
.jp_tittle_name h3{
	font-size:16px;
	font-weight:bold;
	text-transform:uppercase;
	color:#ffffff;
}
.jp_tittle_name h4{
	font-size:16px;
	font-weight:bold;
	text-transform:uppercase;
	color:#ffffff;
	padding-top:5px;
}
.jp_tittle_slider_wrapper{
	float:left;
	width:85%;
	background:#ffffff;
	border:1px solid #282d39;
	border-left:0;
	border-right:0;
}
.jp_tittle_slider_content_wrapper{
	float:left;
	width:100%;
	background:#1a1e27;
}
.jp_tittle_slider_content_wrapper .owl-theme .owl-dots{
	display:none;
}
.jp_tittle_slider_content_wrapper .owl-theme .owl-nav {
	display:none;
}
.jp_tittle_slides_one{
	float:left;
	width:100%;
	padding-top: 20px;
    padding-bottom: 20px;
	border-right:1px solid #282d39;
	padding-left:20px;
	background:#1a1e27;
}
.jp_tittle_side_img_wrapper{
	float:left;
	width:65px;
}
.jp_tittle_side_cont_wrapper{
	float:left;
	width:calc(100% - 65px);
	padding-top: 16px;
    padding-left: 11px;
}
.jp_tittle_side_cont_wrapper h4{
	font-size:14px;
	color:#ffffff;
	font-weight:bold;
}
.jp_tittle_side_cont_wrapper p{
	font-size:14px;
	color:#23c0e9;
}
/*---------- jp tittle slider Wrapper End -------*/
/*---------- jp first sidebar Wrapper Start -------*/
.jp_first_sidebar_main_wrapper{
	float:left;
	width:100%;
	margin-top:30px;
}
.jp_hiring_slider_main_wrapper{
	float:left;
	width:100%;
	background:#1a1e27;
	border:1px solid #282d39;
    padding: 40px 35px;
}
.jp_hiring_heading_wrapper h2{
	font-size:20px;
	color:#ffffff;
	font-weight:900;
	text-transform:uppercase;
	position:relative;
}
.jp_hiring_heading_wrapper h2:after{
	content:'';
	border:1px solid #23c0e9;
	width:30px;
	position:absolute;
	bottom: -15px;
    left: 11px;
}
.jp_hiring_heading_wrapper h2:before{
	content:'';
	border:1px solid #23c0e9;
	width:8px;
	position:absolute;
	bottom: -15px;
    left: 0;
}
.jp_hiring_slider_wrapper{
	float:left;
	width:100%;
	margin-top: 50px;
}
.jp_hiring_slider_wrapper  .owl-theme .owl-dots{
	display:none;
}
.jp_hiring_slider_wrapper .owl-theme .owl-nav {
    position: absolute;
    top: -74px;
    margin: 0;
    left: 0;
    right: 0;
	z-index:1000;
	display:block;
}
.jp_hiring_slider_wrapper .owl-theme .owl-nav .owl-prev{
    font-size: 26px;
    margin: 0;
    padding: 0;
    right: 30px;
	top:0;
	color:#404552;
	background:none;
    position: absolute;
    text-align: center;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_hiring_slider_wrapper  .owl-theme .owl-nav .owl-next{
    font-size: 26px;
    margin: 0;
    padding: 0;
	top:0;
	right:0;
    position: absolute;
	color:#404552;
	background:none;
    text-align: center;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_hiring_slider_wrapper .owl-theme .owl-nav .owl-prev:hover, .jp_hiring_slider_wrapper  .owl-theme .owl-nav .owl-next:hover{
	color:#23c0e9;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_hiring_content_main_wrapper{
	float:left;
	width:100%;
	background:#1a1e27;
	border:1px solid #282d39;
	border-bottom:2px solid #23c0e9;
	text-align:center;
	padding-top:40px;
	padding-bottom:40px;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_hiring_content_wrapper{
	display:inline-block;
}
.jp_hiring_content_wrapper h4{
	font-size:14px;
	color:#ffffff;
	text-transform:uppercase;
	font-weight:bold;
	padding-top:30px;
}
.jp_hiring_content_wrapper p{
	font-size:14px;
	padding-top:5px;
}
.jp_hiring_content_wrapper li a{
	float:left;
	width:110px;
	height:30px;
	line-height:30px;
	text-align:center;
	color:#000000;
	background:#ffffff;
	font-size:12px;
	font-weight:bold;
	-webkit-border-radius:8px;
	-moz-border-radius:8px;
	border-radius:8px;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_hiring_content_wrapper img{
	width:auto !important;
	display:inline-block !important;
}
.jp_hiring_content_main_wrapper:hover{
	border-bottom:2px solid #f36969;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_hiring_content_main_wrapper:hover .jp_hiring_content_wrapper li a{
	background:#f36969;
	color:#ffffff;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
/*---------- jp first sidebar Wrapper End -------*/
/*-- CC Featured product Wrapper Start --*/
.cc_featured_product_main_wrapper{
	width:100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
	padding-top: 30px;
}
.ss_featured_products_wrapper{
	float:left;
	width:100%;
	padding:100px 0px 0px 0px;
}
.ss_heading{
	float:left;
	width:auto;
}
.ss_heading h3{
    float: left;
    width: auto;
    margin: 15px 0px 0px;
    font-size: 20px;
    text-transform: uppercase;
	position:relative;
}
.ss_heading h3:after{
	content:"";
	position:absolute;
	left:0px;
	bottom:-17px;
	background-color:#0091dc;
	width:50px;
	height:1px;
}
.ss_featured_products .item{
	margin-right:1px;
}
.ss_featured_products{
    float: left;
    width: 100%;
    padding: 15px 0px;
}
.ss_featured_products .nav-tabs{
	margin-top:40px;
	margin-bottom:40px;
}
.ss_featured_products  .owl-theme .owl-nav {
    position: absolute;
    bottom: 0;
    margin: 0px auto;
    left: 0;
	right:0;
    display: block;
}
.ss_featured_products .owl-theme .owl-nav .owl-prev{
    font-size: 14px;
    margin: 0;
    padding: 0;
    left: 0;
	top: 30px;
    position: absolute;
    text-align: center;
	background:#1a1e27;
	color:#ffffff;
	border:1px solid #282d39;
	width:60px;
	height:30px;
    line-height:30px;
	-webkit-border-radius:10px;
	-moz-border-radius:10px;
	border-radius:10px;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.ss_featured_products .owl-theme .owl-nav .owl-next{
    font-size: 14px;
    margin: 0;
    padding: 0;
    right: 0;
	top:30px;
    position: absolute;
    text-align: center;
	background:#1a1e27;
	color:#ffffff;
	border:1px solid #282d39;
	width:60px;
	height:30px;
    line-height:30px;
	-webkit-border-radius:10px;
	-moz-border-radius:10px;
	border-radius:10px;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.ss_featured_products  .owl-theme .owl-nav .owl-prev:hover, .ss_featured_products   .owl-theme .owl-nav .owl-next:hover{
	background:#23c0e9;
	border:1px solid #23c0e9;
	color:#ffffff;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.ss_featured_products  .owl-theme .owl-dots{
	display:none;
}
.video_nav_img_wrapper{
	float:left;
	width:100%;
	text-align:center;
}
.video_nav_img ul{
	display:inline-block;  
	position: relative;
    margin: 15px 0;
    z-index: 1000;
}
.video_nav_img li{
	margin-left:10px;
	float:left;
}
.video_nav_img li:first-child{
	margin-left:0;
}
.video_nav_img li a{
	float:left;
	width:30px;
	height:30px;
	line-height:25px;
	-webkit-border-radius:10px;
	-moz-border-radius:10px;
	border-radius:10px;
	border:2px solid #282d39;
	background:#1a1e27;
	color:#ffffff;
}
.type_item{
    border: 1px solid #282d39;
    color:#ffffff;
    background:#1a1e27;
    transition: all 0.5s;
    cursor: pointer;
    text-align: center;
}
.type_item:first-child{
    border-radius: 10px 0 0 10px;
}
.type_item:last-child{
    border-radius:0 10px 10px 0;
}
.type_item:hover{
    color:#ffffff;
    background:#23c0e9;
}
.type_item_active{
    border: 1px solid #282d39;
    color:#ffffff;
    background:#23c0e9;
    transition: all 0.5s;
    cursor: pointer;
    text-align: center;
}
.type_item_active:first-child{
    border-radius: 10px 0 0 10px;
}
.type_item_active:last-child{
    border-radius:0 10px 10px 0;
}
/*.type_item_active:hover{*/
/*    color:#ffffff;*/
/*    background:#1a1e27;*/
/*    transition: all 0.5s;*/
/*    cursor: pointer;*/
/*    text-align: center;*/
/*}*/
/*.type_item_active:focus{*/
/*    color:#ffffff;*/
/*    background:#1a1e27;*/
/*    transition: all 0.5s;*/
/*    cursor: pointer;*/
/*    text-align: center;*/
/*}*/
.type_filter_menu{
    margin-right: 0;
    width: 75%;
}
.nav-tabs > li > span{
    display: block;
    text-align: center;
    height: 100%;
    max-width: 150px;
	text-transform:uppercase;
	font-weight:bold;
	color:#ffffff;
	font-size:14px;
	font-family: 'Lato', sans-serif;
	padding: 13px 12px;
	border-left:1px solid #282d39;
	background:#1a1e27;
	margin-right:0;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.nav-tabs > li:first-child > span{
    border-radius: 10px 0 0 10px;
}
.nav-tabs > li:last-child > span{
    border-radius: 0  10px 10px  0 ;
}
.nav-tabs > li > span:hover{
	/*border:1px solid transparent;*/
	background:#23c0e9;
}
.nav-tabs > li:first-child > span{
	border-left:0;
}
.nav-tabs > li.active > span, .nav-tabs > li.active > span:hover, .nav-tabs > li.active > span:focus{
    display: block;
    text-align: center;
	color:#ffffff;
	border: 1px solid #23c0e9;
    background: #23c0e9;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.nav-tabs > li:first-child.active > span, .nav-tabs > li:first-child.active > span:hover, .nav-tabs > li:first-child.active > span:focus{
	-webkit-border-top-left-radius:10px;
	-moz-border-top-left-radius:10px;
	border-top-left-radius:10px;
	-webkit-border-bottom-left-radius:10px;
	-moz-border-top-bottom-radius:10px;
	border-bottom-left-radius:10px;
}
.nav-tabs > li:last-child.active > span, .nav-tabs > li:last-child.active > span:hover, .nav-tabs > li:last-child.active > span:focus{
	-webkit-border-top-right-radius:10px;
	-moz-border-top-right-radius:10px;
	border-top-right-radius:10px;
	-webkit-border-bottom-right-radius:10px;
	-moz-border-bottom-right-radius:10px;
	border-bottom-right-radius:10px;
}
.nav-tabs > li > span:hover{
	border-left:1px solid #00000026;
}
.nav-tabs > li:first-child > span:hover{
	border-left:0;
    border-radius: 10px 0  0 10px ;
}
.nav-tabs > li:last-child > span:hover{
    border-left:0;
    border-radius:0  10px 10px  0 ;
}
.nav-tabs{
	border-top:1px solid #eeeeee;
	border-bottom:1px solid #eeeeee;
}
.cc_featured_second_section{
	margin-top:40px;
	float:left;
	width:100%;
}
.jp_job_post_main_wrapper{
	float:left;
	width:100%;
	background:#1a1e27;
    padding: 30px;
    border:1px solid #282d39;
	border-bottom:0;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_job_post_side_img{
	float:left;
	width:105px;
}
.jp_job_post_right_cont{
	float:left;
	width:calc(100% - 105px);
	padding-left:30px;
	padding-top:10px;
}
.jp_job_post_right_cont h4{
	font-size:16px;
	color:#ffffff;
	font-weight:bold;
}
.jp_job_post_right_cont p{
	font-size:16px;
	color:#23c0e9;
	padding-top:5px;
}
.jp_job_post_right_cont li{
	margin-left:20px;
	float:left;
}
.jp_job_post_right_cont li i{
	color:#f36969;
}
.jp_job_post_right_cont li:first-child{
	margin-left:0;
	color:#ffffff;
	font-size:16px;
	font-weight:bold;
}
.jp_job_post_right_cont li:last-child{
	color:#6f7e98;
	font-size:16px;
}
.jp_job_post_heading_wrapper{
	float:left;
}
.jp_job_post_right_btn_wrapper ul{
	float:right;
	margin-top: 15px;
}
.jp_job_post_right_btn_wrapper li{
	float:left;
	margin-left:20px;

}
.jp_job_post_right_btn_wrapper li:first-child{
	margin-left:0;
}
.like_btn{
    width:30px;
    height:30px;
    border:1px solid #282d39;
    text-align:center;
    line-height:30px;
    -webkit-border-radius:10px;
    -moz-border-radius:10px;
    border-radius:10px;
    color:#f36969;
    background:transparent;
    transition: all 0.5s;
}
.like_btn:hover{
    background:#f36969;
    color:#ffffff;
    border:1px solid #f36969;
    transition: all 0.5s;
}
.liked_project{
    background:#f36969;
    color:#ffffff;
    border:1px solid #f36969;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.liked_project:hover{
    background: transparent;
    color:#f36969;
    border:1px solid #282d39;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}

.jp_job_post_right_btn_wrapper li:first-child button{
	float:left;
	width:30px;
	height:30px;
	border:1px solid #282d39;
	text-align:center;
	line-height:30px;
	-webkit-border-radius:10px;
	-moz-border-radius:10px;
	border-radius:10px;
	color:#f36969;
	background:transparent;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
} 
.jp_job_post_right_btn_wrapper li:first-child button:hover{
	background:#f36969;
	color:#ffffff;
	border:1px solid #f36969;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_job_post_right_btn_wrapper li:first-child button.liked_project{
    background:#f36969;
    color:#ffffff;
    border:1px solid #f36969;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_job_post_right_btn_wrapper li:first-child button.liked_project:hover{
    background: transparent;
    color:#f36969;
    border:1px solid #282d39;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_job_post_right_btn_wrapper li:nth-child(2){
	float:none;
	margin-left:50px;
}
.jp_job_post_right_btn_wrapper li:nth-child(2) button{
    border: none;
	float:left;
	width:100px;
	/*height:80px;*/
	text-align:center;
	background:#37d09c;
	color:#ffffff;
	font-size:16px;
	/*text-transform:uppercase;*/
    padding: 10px 0;
    line-height: 18px;
	border-radius:10px;
}
.jp_job_post_right_btn_wrapper li:last-child{
	margin-left:50px;
	margin-top:60px;
	float:none;
}
.jp_job_post_right_btn_wrapper li:last-child button{
    border: none;
	float:left;
	width:100px;
	height:40px;
	line-height:30px;
	text-align:center;
	/*background:#f36969;*/
	color:#ffffff;
	font-size:14px;
	text-transform:uppercase;
	-webkit-border-radius:10px;
	-moz-border-radius:10px;
	border-radius:10px;
}
.jp_job_post_keyword_wrapper{
	float:left;
	width:100%;
    padding: 20px 30px;
    border:1px solid #282d39;
	background:#222631;
	border-top:0;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_job_post_keyword_wrapper li{
	float:left;
	margin-left:20px;
    color:#6f7e98;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_job_post_keyword_wrapper li:first-child{
	margin-left:0;
	color:#6f7e98;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_job_post_keyword_wrapper li i{
	padding-right:5px;
	color:#23c0e9;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}

.jp_job_post_main_wrapper_cont{
	float:left;
	width:100%;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
    margin-top:30px;
}
.jp_job_post_main_wrapper_cont:hover .jp_job_post_main_wrapper{
	border:1px solid #23c0e9;
	border-bottom:0;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_job_post_main_wrapper_cont:hover .jp_job_post_keyword_wrapper{
	border:1px solid #23c0e9;
	border-top:0;
	background:#23c0e9;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_job_post_main_wrapper_cont:hover .jp_job_post_keyword_wrapper li,
.jp_job_post_main_wrapper_cont:hover .jp_job_post_keyword_wrapper li i,
.jp_job_post_main_wrapper_cont:hover .jp_job_post_keyword_wrapper li a{
	color:#ffffff;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_job_post_main_wrapper_cont2{
	margin-top:35px;
}
.jp_register_section_main_wrapper{
	float:left;
	width:100%;
	margin-top:40px;
}
.jp_regis_left_side_box_wrapper{
	float:left;
	width:50%;
	text-align:center;
	background:#1a1e27;
	border:1px solid #282d39;
	border-bottom:1px solid #23c0e9;
	border-right:0;
	padding-top:85px;
	padding-bottom:83px;
}
.jp_regis_left_side_box{
	display:inline-block;
}
.jp_regis_left_side_box h4{
	font-size:20px;
	font-weight:bold;
	color:#ffffff;
	text-transform:uppercase;
	padding-top:15px;
	position:relative;
}
.jp_regis_left_side_box h4:before {
    content: '';
    border: 1px solid #23c0e9;
    width: 8px;
    position: absolute;
    bottom: -15px;
    left: -45px;
	right:0;
	margin:0px auto;
}
.jp_regis_left_side_box h4:after {
    content: '';
    border: 1px solid #23c0e9;
    width: 30px;
    position: absolute;
	 bottom: -15px;
    left: 0;
	right:0;
	margin:0px auto;
}
.jp_regis_left_side_box p{
	padding-top:50px;
}
.jp_regis_left_side_box ul{
	display:inline-block;
	margin-top:25px;
}
.jp_regis_left_side_box li a{
	float:left;
	width:230px;
	height:50px;
	text-align:center;
	line-height:50px;
	color:#ffffff;
	font-weight:bold;
	background:#23c0e9;
	-webkit-border-radius: 10px; 
  -moz-border-radius: 10px; 
   border-radius: 10px; 
   -webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_regis_left_side_box li a:hover{
	background:#f36964;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_regis_right_side_box_wrapper{
	float:left;
	width:50%;
	padding-top:85px;
	padding-bottom:85px;
	background:url('../images/content/register_bg.jpg') 50% 0 repeat-y;
	background-position:center 0;
	background-size:cover;
	background-repeat:no-repeat;
	position:relative;
	text-align:center;
	border-bottom:1px solid #282d39;
}
.jp_regis_right_img_overlay{
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background:rgba(14, 20, 39, 0.8313725490196079);
}
.jp_regis_right_side_box{
	display:inline-block;
	position:relative;
}
.jp_regis_right_side_box h4{
	font-size:20px;
	font-weight:bold;
	color:#ffffff;
	text-transform:uppercase;
	padding-top:15px;
	position:relative;
}
.jp_regis_right_side_box h4:before {
    content: '';
    border: 1px solid #23c0e9;
    width: 8px;
    position: absolute;
    bottom: -15px;
    left: -45px;
	right:0;
	margin:0px auto;
}
.jp_regis_right_side_box h4:after {
    content: '';
    border: 1px solid #23c0e9;
    width: 30px;
    position: absolute;
	 bottom: -15px;
    left: 0;
	right:0;
	margin:0px auto;
}
.jp_regis_right_side_box p{
	padding-top:50px;
	color:#ffffffa3;
}
.jp_regis_right_side_box ul{
	display:inline-block;
	margin-top:25px;
}
.jp_regis_right_side_box li a{
	float:left;
	width:230px;
	height:50px;
	text-align:center;
	line-height:50px;
	color:#ffffff;
	font-weight:bold;
	background:#f36969;
	-webkit-border-radius: 10px; 
  -moz-border-radius: 10px; 
   border-radius: 10px; 
   -webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_regis_right_side_box li a:hover{
	background:#23c0e9;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_regis_center_tag_wrapper{
	width:70px;
	height:70px;
	background:#23c0e9;
	position: absolute;
    left: -34px;
    top: 50%;
    margin-top: -20px;
	-webkit-border-radius: 20px; 
   -moz-border-radius: 20px; 
    border-radius: 20px; 
	-ms-transform: rotate(45deg); 
    -webkit-transform: rotate(45deg); 
    transform: rotate(45deg);
}
.jp_regis_center_tag_wrapper p{
	color:#ffffff;
	font-weight:bold;
	-ms-transform: rotate(-45deg); 
    -webkit-transform: rotate(-45deg); 
    transform: rotate(-45deg);
	margin-top: 23px;
}
.jp_first_right_sidebar_main_wrapper{
	float:left;
	width:100%;
}
.jp_add_resume_wrapper{
    margin-top: 25px;
	background:url('../images/content/resume-bg.jpg') 50% 0 repeat-y;
	width:100%;
	height:300px;
	background-position:center 0;
	background-size:cover;
	background-repeat:no-repeat;
	position:relative;
	text-align:center;
	padding-left:30px;
	padding-right:30px;
}
.jp_add_resume_img_overlay{
	position:absolute;
	top:0%;
	left:0%;
	right:0%;
	bottom:0%;
	background:rgba(14, 20, 39, 0.8313725490196079);
}
.jp_add_resume_cont{
	position:relative;
	display:inline-block;
	padding-top:40px;
	padding-bottom:40px;
}
.jp_add_resume_cont h4{
	font-size:16px;
	color:#ffffff;
	padding-top:25px;
	line-height: 25px;
	position:relative;
}
.jp_add_resume_cont h4:before {
    content: '';
    border: 1px solid #23c0e9;
    width: 8px;
    position: absolute;
    bottom: -15px;
    left: -45px;
    right: 0;
    margin: 0px auto;
}
.jp_add_resume_cont h4:after {
    content: '';
    border: 1px solid #23c0e9;
    width: 30px;
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
	margin: 0px auto;
}
.jp_add_resume_cont ul{
	display:inline-block;
	margin-top:35px;
}
.jp_add_resume_cont li a{
	float:left;
	width:160px;
	height:50px;
	text-align:center;
	line-height:50px;
	color:#ffffff;
	font-weight:bold;
	background:#23c0e9;
	-webkit-border-radius: 10px; 
  -moz-border-radius: 10px; 
   border-radius: 10px; 
   -webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_add_resume_cont li a:hover{
	background:#f36969;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_spotlight_main_wrapper{
	float:left;
	width:100%;
	margin-top:30px;
}
.spotlight_header_wrapper{
	float:left;
	width:100%;
	background:#23c0e9;
	padding-top:15px;
	padding-bottom:15px;
	padding-left:20px;
	-webkit-border-top-left-radius: 10px; 
  -moz-border-top-left-radius: 10px; 
   border-top-left-radius: 10px; 
   -webkit-border-top-right-radius: 10px; 
  -moz-border-top-right-radius: 10px; 
   border-top-right-radius: 10px; 
}
.spotlight_header_wrapper h4{
	font-size:16px;
	color:#ffffff;
}
.jp_spotlight_slider_wrapper{
	float:left;
	width:100%;
    padding: 30px 30px 40px;
    background:#1a1e27;
	border:1px solid #282d39;
	border-top:0;
	border-bottom:1px solid #23c0e9;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_spotlight_slider_wrapper:hover{
	border-bottom:1px solid #f36969;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
} 
.jp_spotlight_slider_wrapper  .owl-theme .owl-dots{
	display:none;
}
.jp_spotlight_slider_wrapper .owl-theme .owl-nav {
    position: absolute;
    top: -66px;
    margin: 0;
    left: 0;
    right: 0;
	z-index:1000;
	display:block;
}
.jp_spotlight_slider_wrapper .owl-theme .owl-nav .owl-prev{
    font-size: 26px;
    margin: 0;
    padding: 0;
    right: 20px;
	top:0;
	color:#ffffffab;
	background:none;
    position: absolute;
    text-align: center;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_spotlight_slider_wrapper  .owl-theme .owl-nav .owl-next{
    font-size: 26px;
    margin: 0;
    padding: 0;
	top:0;
	right:-10px;
    position: absolute;
	color:#ffffffab;
	background:none;
    text-align: center;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_spotlight_slider_wrapper .owl-theme .owl-nav .owl-prev:hover, .jp_spotlight_slider_wrapper  .owl-theme .owl-nav .owl-next:hover{
	color:#ffffff;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_spotlight_slider_cont_Wrapper h4{
	font-size:16px;
	color:#ffffff;
	font-weight:bold;
	padding-top:40px;
}
.jp_spotlight_slider_cont_Wrapper p{
	font-size:16px;
	color:#23c0e9;
	padding-top:5px;
}
.jp_spotlight_slider_cont_Wrapper li{
	float:left;
}
.jp_spotlight_slider_cont_Wrapper li i{
	color:#f36969;
}
.jp_spotlight_slider_cont_Wrapper li:first-child{
	margin-left:0;
	color:#ffffff;
	font-size:16px;
	font-weight:bold;
}
.jp_spotlight_slider_cont_Wrapper li:last-child{
	color:#6f7e98;
	font-size:16px;
	margin-top:20px;
}
.jp_spotlight_slider_btn_wrapper{
	float:left;
	width:100%;
	text-align:center;
}
.jp_spotlight_slider_btn ul{
	display:inline-block;
	margin-top:35px;
}
.jp_spotlight_slider_btn li a{
	float:left;
	width:160px;
	height:50px;
	text-align:center;
	line-height:50px;
	color:#ffffff;
	font-weight:bold;
	background:#f36969;
	-webkit-border-radius: 10px; 
  -moz-border-radius: 10px; 
   border-radius: 10px; 
   -webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_spotlight_slider_btn li a:hover{
	background:#23c0e9;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_rightside_job_categories_wrapper{
	float:left;
	width:100%;
	margin-top:30px;
}
.jp_rightside_job_categories_heading{
	float:left;
	width:100%;
	background:#23c0e9;
	padding-top:15px;
	padding-bottom:15px;
	padding-left:20px;
	-webkit-border-top-left-radius: 10px; 
  -moz-border-top-left-radius: 10px; 
   border-top-left-radius: 10px; 
   -webkit-border-top-right-radius: 10px; 
  -moz-border-top-right-radius: 10px; 
   border-top-right-radius: 10px; 
}
.jp_rightside_job_categories_heading h4{
	font-size:16px;
	color:#ffffff;
}
.jp_rightside_job_categories_content{
	float:left;
	width:100%;
	background:#1a1e27;
	padding-left:20px;
	padding-bottom:40px;
	border:1px solid #282d39;
	border-bottom:1px solid #23c0e9;
	border-top:0;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_rightside_job_categories_wrapper:hover .jp_rightside_job_categories_content{
	border-bottom:1px solid #f36969;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_rightside_job_categories_content ul{
	margin-top:30px;
}
.jp_rightside_job_categories_content li{
	margin-top:20px;
}
.jp_rightside_job_categories_content li {
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_rightside_job_categories_content li:hover{
	color:#23c0e9;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_rightside_job_categories_content li:first-child{
	margin-top:0;
}
.jp_rightside_job_categories_content li i{
	padding-right:5px;
	color:#f36969;
}
/*.jp_rightside_job_categories_content li span{*/
/*	color:#23c0e9;*/
/*}*/
.jp_rightside_job_categories_content .active_category{
	font-size:14px;
	font-weight:bold;
	color:#23c0e9;
	text-transform:uppercase;
}
.jp_rightside_job_categories_content .active_category i{
	color:#23c0e9;
}
.jp_rightside_career_wrapper{
	float:left;
	width:100%;
	margin-top:30px;
}
.jp_rightside_career_heading{
	float:left;
	width:100%;
	background:#23c0e9;
	padding-top:15px;
	padding-bottom:15px;
	padding-left:20px;
	-webkit-border-top-left-radius: 10px; 
  -moz-border-top-left-radius: 10px; 
   border-top-left-radius: 10px; 
   -webkit-border-top-right-radius: 10px; 
  -moz-border-top-right-radius: 10px; 
   border-top-right-radius: 10px; 
}
.jp_rightside_career_heading h4{
	font-size:16px;
	color:#ffffff;
}
.jp_rightside_career_main_content{
	float:left;
	width:100%;
	background:#1a1e27;
	padding-bottom:40px;
	border:1px solid #282d39;
	border-bottom:1px solid #22c0e9;
	border-top:0;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_rightside_career_main_content:hover{
	border-bottom:1px solid #f36969;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_rightside_career_content_wrapper{
	float:left;
	width:100%;
	padding-top:40px;
	padding-left:30px;
}
.jp_rightside_career_img{
	float:left;
	width:80px;
}
.jp_rightside_career_img_cont{
	float:left;
	width:calc(100% - 80px);
	padding-left:20px;
	padding-right:20px;
	padding-top:10px;
}
.jp_rightside_career_img_cont h4{
	font-size:14px;
	color:#ffffff;
	font-weight:bold;
}
.jp_rightside_career_img_cont p{
	font-size:12px;
	padding-top:3px;
}
.jp_rightside_career_img_cont p i{
	color:#f36969;
	font-size:14px;
	font-weight:bold;
}
.jp_rightside_career_btn{
	float:left;
	width:100%;
	margin-top:20px;
}
.jp_rightside_career_btn a{
	text-transform:uppercase;
	color:#22c0e9;
	padding-left:30px;
}
.jp_rightside_career_btn a i{
	color:#22c0e9;
}
/*-- jp Featured product Wrapper End --*/
/*-- jp counter Wrapper Start --*/
.jp_counter_main_wrapper{
	float:left;
	width:100%;
	text-align:center;
	margin-top:30px;
}
.gc_counter_cont_wrapper{
	float:left;
	width:25%;
	display:inline-block;
	padding-top:50px;
	padding-bottom:50px;
	background:#23c0e9;
}
.gc_counter_cont_wrapper2{
	float:left;
	width:25%;
	display:inline-block;
	padding-top:50px;
	padding-bottom:50px;
	background:#22b5db;
	border-left:0;
	border-right:0;
}
.gc_counter_cont_wrapper3{
	float:left;
	width:25%;
	display:inline-block;
	padding-top:50px;
	padding-bottom:50px;
	background:#23c0e9;
	border-right:0;
}
.gc_counter_cont_wrapper4{
	float:left;
	width:25%;
	display:inline-block;
	padding-top:50px;
	padding-bottom:50px;
	background:#22b5db;
}
.count-description {
	margin-top:30px;
}
.count-description span{
	font-size:50px;
	color:#ffffff;
	margin-top:50px;
	font-weight:900;
	position:relative;
}
.count-description i{
	font-size:30px;
	color:#ffffff;
	padding-left:10px;
	position:relative;
	top:-5px;
}
.con1, .con2, .con3, .con4{
	font-family: 'Lato', sans-serif;
	font-size: 16px;
	color:#ffffff;
	margin-top:40px;
}
.count-description span:before {
    content: '';
    border: 1px solid #ffffff;
    width: 8px;
    position: absolute;
    bottom: -15px;
    left: -3px;
    right: 0;
    margin: 0px auto;
}
.count-description span:after {
    content: '';
    border: 1px solid #ffffff;
    width: 30px;
    position: absolute;
    bottom: -15px;
    left: 0;
    right: -40px;
    margin: 0px auto;
}
/*-- jp counter Wrapper End --*/
/*-- jp Best deals Wrapper Start --*/
.jp_best_deals_main_wrapper{
	float:left;
	width:100%;
	margin-top:30px;
}
.jp_best_deal_slider_main_wrapper{
	float:left;
	width:100%;
}
.jp_best_deal_heading_wrapper h2{
	font-size:20px;
	color:#ffffff;
	font-weight:900;
	text-transform:uppercase;
	position:relative;
}
.jp_best_deal_heading_wrapper h2:after{
	content:'';
	border:1px solid #23c0e9;
	width:30px;
	position:absolute;
	bottom: -15px;
    left: 11px;
}
.jp_best_deal_heading_wrapper h2:before{
	content:'';
	border:1px solid #23c0e9;
	width:8px;
	position:absolute;
	bottom: -15px;
    left: 0;
}
.jp_best_deal_slider_wrapper{
	float:left;
	width:100%;
	margin-top: 50px;
}
.jp_best_deal_slider_wrapper  .owl-theme .owl-dots{
	display:none;
}
.jp_best_deal_slider_wrapper .owl-theme .owl-nav {
    position: absolute;
    top: -74px;
    margin: 0;
    left: 0;
    right: 0;
	z-index:1000;
	display:block;
}
.jp_best_deal_slider_wrapper .owl-theme .owl-nav .owl-prev{
    font-size: 26px;
    margin: 0;
    padding: 0;
    right: 30px;
	top:0;
	color:#404552;
	background:none;
    position: absolute;
    text-align: center;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_best_deal_slider_wrapper  .owl-theme .owl-nav .owl-next{
    font-size: 26px;
    margin: 0;
    padding: 0;
    right: 0;
	top:0;
    position: absolute;
	color:#404552;
	background:none;
    text-align: center;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_best_deal_slider_wrapper .owl-theme .owl-nav .owl-prev:hover, .jp_best_deal_slider_wrapper  .owl-theme .owl-nav .owl-next:hover{
	color:#23c0e9;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_best_deal_main_cont_wrapper{
	float:left;
	width:100%;
	background:#1a1e27;
	padding:40px;
	border:1px solid #282d39;
	border-bottom:1px solid #22b5db;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_best_deal_main_cont_wrapper:hover{
	border-bottom:1px solid #f36969;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_best_deal_icon_sec{
	float:left;
	width:40px;
	padding-top: 10px;
}
.jp_best_deal_cont_sec{
	float:left;
	width:calc(100% - 40px);
	padding-left:20px;
}
.jp_best_deal_cont_sec h4{
	font-size:20px;
}
.jp_best_deal_cont_sec h4 a{
	color:#ffffff;
	font-weight:bold;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_best_deal_main_cont_wrapper:hover .jp_best_deal_cont_sec h4 a{
	color:#f36969;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_best_deal_icon_sec i:before {
    font-size: 35px;
    color: #22b5db;
    margin-left: 0;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_best_deal_main_cont_wrapper:hover .jp_best_deal_icon_sec i:before{
	color:#f36969;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_best_deal_cont_sec p{
	padding-top:15px;
}
.jp_best_deal_main_cont_wrapper2{
	margin-top:30px;
}
.jp_best_deal_right_sec_wrapper{
	margin-top:0;
}
.jp_best_deal_right_content{
	padding-top:27px;
}
/*-- jp Best deals Wrapper End --*/
/*-- jp Client Wrapper Start --*/
.jp_client_slider_main_wrapper{
	float:left;
	width:100%;
	margin-top:30px;
}
.jp_client_heading_wrapper h2{
	font-size:20px;
	color:#ffffff;
	font-weight:900;
	text-transform:uppercase;
	position:relative;
}
.jp_client_heading_wrapper h2:after{
	content:'';
	border:1px solid #23c0e9;
	width:30px;
	position:absolute;
	bottom: -15px;
    left: 11px;
}
.jp_client_heading_wrapper h2:before{
	content:'';
	border:1px solid #23c0e9;
	width:8px;
	position:absolute;
	bottom: -15px;
    left: 0;
}
.jp_client_slider_wrapper{
	float:left;
	width:100%;
	margin-top: 50px;
}
.jp_client_slider_wrapper  .owl-theme .owl-dots{
	display:none;
}
.jp_client_slider_wrapper .owl-theme .owl-nav {
    position: absolute;
    top: -74px;
    margin: 0;
    left: 0;
    right: 0;
	z-index:1000;
	display:block;
}
.jp_client_slider_wrapper .owl-theme .owl-nav .owl-prev{
    font-size: 26px;
    margin: 0;
    padding: 0;
    right: 30px;
	top:0;
	color:#ffffff85;
	background:none;
    position: absolute;
    text-align: center;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_client_slider_wrapper  .owl-theme .owl-nav .owl-next{
    font-size: 26px;
    margin: 0;
    padding: 0;
    right: 0;
	top:0;
    position: absolute;
	color:#ffffff85;
	background:none;
    text-align: center;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_client_slider_wrapper .owl-theme .owl-nav .owl-prev:hover, .jp_client_slider_wrapper  .owl-theme .owl-nav .owl-next:hover{
	color:#ffffff;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_first_client_slider_wrapper{
	background:url('../images/content/client_bg.jpg') 50% 0 repeat-y;
	background-position:center 0;
	background-size:cover;
	background-repeat:no-repeat;
	float:left;
	width:100%;
	height:100%;
	position:relative;
	padding:40px;
}
.jp_first_client_slider_img_overlay{
	position:absolute;
	top:0%;
	right:0%;
	left:0%;
	bottom:0%;
	background:#0e1427d9;
}
.jp_client_slide_show_wrapper{
	float:left;
	width:100%;
}
.jp_client_slider_img_wrapper{
	float:left;
	width:120px;
}
.jp_client_slider_img_wrapper img{
	-webkit-border-radius: 30px; 
  -moz-border-radius: 30px; 
   border-radius: 30px; 
}
.jp_client_slider_cont_wrapper{
	float:left;
	width:calc(100% - 120px);
	padding-left:35px;
	padding-top: 18px;
}
.jp_client_slider_cont_wrapper p{
	font-size:20px;
	font-style:italic;
	color:#ffffffd4;
}
.jp_client_slider_cont_wrapper i{
	color:#f1a835;
}
.jp_client_slider_cont_wrapper span{
	color:#ffffff;
	font-size:16px;
	font-family: 'Montserrat', sans-serif;
	margin-left:10px;
	font-weight:bold;
}
.jp_client_slider_cont_wrapper span b{
	color:#23c0e9;
	font-family: 'Lato', sans-serif;
}
/*-- jp Client Wrapper End --*/
/*-- jp pricing Wrapper Start --*/
.jp_pricing_main_wrapper{
	float:left;
	width:100%;
	margin-top:30px;
	padding-bottom:30px;
	text-align:center;
}
.stars_wrapper{
	float:left;
}
.stars_wrapper{
	width:100%;
	height:40px;
	background:#fca900;
	color:#fff;
	-ms-transform: rotate(-40deg);
    -webkit-transform: rotate(-40deg);
    transform: rotate(-40deg);
	margin-left:-100px;
}
.stars_wrapper i{
	position:relative;
	left:-35px;
	top:5px;
}
.price_box1 span, .price_box2 span, .price_box3 span{
	font-size:30px;
	color:#ffffff;
	font-family: 'Titillium Web', sans-serif;
	font-weight:bold;
}
.price_box1 h1, .price_box2 h1, .price_box3 h1{
	font-size:25px;
	padding-top: 11px;
	-ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
	color:#ffffff;
    margin-top: 31px;
    font-weight: bold;
    margin-left: -14px;
}
.box1_heading_wrapper{
	width:100%;
	border-bottom:2px solid #282d39;
	padding-bottom:130px;
}
.pricing_box1_wrapper{
	background:#1a1e27;
	position:relative;
	overflow:hidden;
	border:1px solid #282d39;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.pricing_border_box1_wrapper:hover{
	border-bottom:1px solid #23c0e9;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.pricing_border_box2_wrapper:hover{
	border-bottom:1px solid #f36969;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.pricing_border_box3_wrapper:hover{
	border-bottom:1px solid #37d09c;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.box1_heading_wrapper h4{
	font-size:20px;
	font-weight:bold;
	padding-top:50px;
	color:#ffffff;
	text-transform:uppercase;
	position:relative;
}
.box1_heading_wrapper h4:before {
    content: '';
    border: 1px solid #23c0e9;
    width: 8px;
    position: absolute;
    bottom: -15px;
    left: -45px;
    right: 0;
    margin: 0px auto;
}
.box1_heading_wrapper h4:after {
    content: '';
    border: 1px solid #23c0e9;
    width: 30px;
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
    margin: 0px auto;
}
.box2_heading_wrapper h4{
	font-size:20px;
	font-weight:bold;
	padding-top:50px;
	color:#ffffff;
	text-transform:uppercase;
	position:relative;
}
.box2_heading_wrapper h4:before {
    content: '';
    border: 1px solid #f36969;
    width: 8px;
    position: absolute;
    bottom: -15px;
    left: -45px;
    right: 0;
    margin: 0px auto;
}
.box2_heading_wrapper h4:after {
    content: '';
    border: 1px solid #f36969;
    width: 30px;
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
    margin: 0px auto;
}
.box3_heading_wrapper h4{
	font-size:20px;
	font-weight:bold;
	padding-top:50px;
	color:#ffffff;
	text-transform:uppercase;
	position:relative;
}
.box3_heading_wrapper h4:before {
    content: '';
    border: 1px solid #37d09c;
    width: 8px;
    position: absolute;
    bottom: -15px;
    left: -45px;
    right: 0;
    margin: 0px auto;
}
.box3_heading_wrapper h4:after {
    content: '';
    border: 1px solid #37d09c;
    width: 30px;
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
    margin: 0px auto;
}
.price_box1_wrapper{
	text-align:center;
}
.price_box1{
	width:120px;
	height:120px;
	border:2px solid #23c0e9;
	-webkit-border-radius:38px;
	-moz-border-radius:38px;
	border-radius:38px;
	display:inline-block;
	margin-top:-75px;
	line-height: 55px;
	z-index:1;
	background:#23c0e9;
	    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
	    position: relative;
    top: -9px;
}
.price_box2{
	width:120px;
	height:120px;
	border:2px solid #f36969;
	-webkit-border-radius:38px;
	-moz-border-radius:38px;
	border-radius:38px;
	display:inline-block;
	margin-top:-75px;
	line-height: 55px;
	z-index:1;
	background:#f36969;
	    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
	    position: relative;
    top: -9px;
}
.price_box3{
	width:120px;
	height:120px;
	border:2px solid #37d09c;
	-webkit-border-radius:38px;
	-moz-border-radius:38px;
	border-radius:38px;
	display:inline-block;
	margin-top:-75px;
	line-height: 55px;
	z-index:1;
	background:#37d09c;
	    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
	    position: relative;
    top: -9px;
}
.pricing_cont{
	display:inline-block;
}
.pricing_cont ul{
	list-style:none;
	padding:0;
	margin-top:60px;
	text-align: left;
}
.pricing_cont li{
	font-family: 'Lato', sans-serif;
	font-size: 20px;
	color:#ffffff;
	margin-top:25px;
}
.pricing_btn_wrapper{
	text-align:center;
}
.pricing_btn1, .pricing_btn2, .pricing_btn3{
	display:inline-block;
	padding-bottom:50px;
	
}
.pricing_btn1 ul, .pricing_btn2 ul, .pricing_btn3 ul{
	padding:0;
	list-style:none;
	margin-top:60px;
}
.pricing_btn1 li a{
	width:170px;
	height:50px;
	float:left;
	color:#000000;
	background:#ffffff;
	text-align:center;
	line-height:45px;
	-webkit-border-radius:10px;
	-moz-border-radius:10px;
	border-radius:10px;
	text-transform:uppercase;
	font-size:14px;
	font-weight:bold;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
	letter-spacing:2px;
	border:2px solid #0e1427;
}
.pricing_btn2 li a{
	width:170px;
	height:50px;
	float:left;
	color:#000000;
	background:#ffffff;
	text-align:center;
	line-height:45px;
	-webkit-border-radius:10px;
	-moz-border-radius:10px;
	border-radius:10px;
	text-transform:uppercase;
	font-size:14px;
	font-weight:bold;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
	letter-spacing:2px;
	border:2px solid #0e1427;
}
.pricing_btn3 li a{
	width:170px;
	height:50px;
	float:left;
	color:#000000;
	background:#ffffff;
	text-align:center;
	line-height:45px;
	-webkit-border-radius:10px;
	-moz-border-radius:10px;
	border-radius:10px;
	text-transform:uppercase;
	font-size:14px;
	font-weight:bold;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
	letter-spacing:2px;
	border:2px solid #0e1427;
}
.pricing_box1_wrapper:hover .pricing_btn1 li a{
	background:#23c0e9;
	color:#ffffff;
	border:2px solid #23c0e9;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.pricing_box1_wrapper:hover .pricing_btn2 li a{
	background:#f36969;
	color:#ffffff;
	border:2px solid #f36969;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.pricing_box1_wrapper:hover .pricing_btn3 li a{
	background:#37d09c;
	color:#ffffff;
	border:2px solid #37d09c;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_pricing_label_wrapper{
	width: 40px;
    height: 69px;
	background:#f1a835;
	position:absolute;
	top:-100px;
	right: 20px;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_pricing_label_wrapper:after{
	content:'';
	border-bottom: 20px solid #1a1e27;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
	position:absolute;
	left:0;
	right:0;
	bottom:0;
}
.pricing_box1_wrapper:hover .jp_pricing_label_wrapper{
	top:0;
	padding-top: 12px;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_pricing_label_wrapper i{
	color:#ffffff;
	-webkit-transition: all 0.9s;
	-o-transition: all 0.9s;
	-ms-transition: all 0.9s;
	-moz-transition: all 0.9s;
	transition: all 0.9s;
}
.pricing_box1_wrapper:hover .jp_pricing_label_wrapper i{
	-ms-transform: rotate(360deg); /* IE 9 */
    -webkit-transform: rotate(360deg); /* Chrome, Safari, Opera */
    transform: rotate(360deg);
	-webkit-transition: all 0.9s;
	-o-transition: all 0.9s;
	-ms-transition: all 0.9s;
	-moz-transition: all 0.9s;
	transition: all 0.9s;
}
/*-- jp pricing Wrapper Start --*/
/*-- jp downlord Wrapper Start --*/
.jp_downlord_main_wrapper{
	background:url('../images/content/down_bg.jpg') 50% 0 repeat-y;
	background-position:center 0;
	background-size:cover;
	background-repeat:no-repeat;
	float:left;
	width:100%;
	height:100%;
	position:relative;
	padding-top:80px;
}
.jp_downlord_img_overlay{
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background:#23c0e9e0;
}
.ss_download_wrapper_details{
	float:left;
	width:100%;
	padding-top:120px;
}
.ss_download_wrapper_details h1{
    float: left;
    width: 100%;
    color: #ffffff;
    font-size: 44px;
    margin: 0px 0px 30px 0px;
    padding-bottom: 15px;
    position: relative;
    font-weight: 100;
	text-transform:uppercase;
}
.ss_download_wrapper_details h1:before {
    content: '';
    border: 1px solid #ffffffcf;
    width: 8px;
    position: absolute;
    bottom: -6px;
    left: 5px;
}
.ss_download_wrapper_details h1:after {
    content: '';
    border: 1px solid #ffffffcf;
    width: 30px;
    position: absolute;
    bottom: -6px;
    left: 17px;
}
.ss_download_wrapper_details h1 span{
	font-weight:900;
}
.ss_download_wrapper_details p{
	float:left;
	width:100%;
	font-size:20px;
	color:#ffffff;
	margin:0px 0px 50px 0px;
	font-style:italic;
}
.ss_download_wrapper_details img{
	position:absolute;
	top:-50px;
	right:0px;
}
.ss_download_wrapper_details a{
    float: left;
    width: auto;
    height: 60px;
    line-height: 60px;
    -webkit-border-radius:10px;
	-moz-border-radius:10px;
	border-radius:10px;
    text-transform: uppercase;
    font-weight: bold;
    color: #000000;
    font-size: 16px;
    text-align: center;
    padding-right: 30px;
}
.ss_download_wrapper_details a span{
	float: left;
    width: 60px;
    border-right: 1px solid #e9e9e9;
    margin-right: 25px;
}
.ss_download_wrapper_details a span i{
	font-size:22px;
	color:#000000;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.ss_download_wrapper_details a.ss_appstore:hover i{
	color:#ffffff;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.ss_download_wrapper_details a.ss_appstore{
	margin-right:20px;
	background:#ffffff;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.ss_download_wrapper_details a.ss_appstore:hover{
	background:#000000;
	color:#ffffff;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.ss_download_wrapper_details a.ss_appstore:hover .ss_download_wrapper_details a span i{
	color:#ffffff;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.ss_download_wrapper_details a.ss_playstore{
	background:#ffffff;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.ss_download_wrapper_details a.ss_playstore span{
    border-right: 1px solid #e9e9e9;
}
.ss_download_wrapper_details a.ss_playstore:hover{
	background:#000000;
	color:#ffffff;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.ss_download_wrapper_details a.ss_playstore:hover i{
	color:#ffffff;
	-webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
/*-- jp downlord Wrapper End --*/
/*-- Newsletter css Start --*/
.jp_newsletter_wrapper{
	float:left;
	width:100%;
	background:#ffffff14;
	padding-top:60px;
	padding-bottom:60px;
	position:relative;
}
.jp_newsletter_img_overlay_wrapper{
	position:absolute;
	top:0%;
	left:0%;
	right:0%;
	bottom:0%;
	background:#0e1427eb;
}
.jp_newsletter_text{
	float:left;
	width:100%;
	padding-top: 2px;
}
.jp_newsletter_text h3{
    float: left;
    width: 100%;
    font-size: 30px;
    color: rgba(255, 255, 255, 0.76);
    margin: 8px 0px;
    text-transform: uppercase;
}
.jp_newsletter_text h3 span{
	font-weight:bold;
	color:#ffffff;
}
.jp_newsletter_text h3 i{
    margin-right: 5px;
    font-size: 30px;
    color: #ffffff;
}
.jp_newsletter_field{
	float:left;
	width:100%;
}
.jp_newsletter_field input{
	float:left;
	width:calc(95% - 160px);
	border:2px solid transparent;
	height:50px;
	line-height:50px;
    color:#6f7e98;
	background-color:#ffffff;
	-webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
	position:relative;
    padding: 0px 15px 0px 40px;
}
.jp_newsletter_field i{
	position:absolute;
	left: 29px;
    z-index: 1;
    top: 17px;
	color:#23c0e9;
}
.jp_newsletter_field input::-webkit-input-placeholder {
	color: #6f7e98;
}
.jp_newsletter_field input::-moz-placeholder {
	color: #6f7e98;
}
.jp_newsletter_field input:-ms-input-placeholder {
	color: #6f7e98;
}
.jp_newsletter_field input:-moz-placeholder {
	color: #6f7e98;
}
.jp_newsletter_field button{
	float:right;
	width:160px;
	height:50px;
	font-size:14px;
	text-transform:uppercase;
	/*border-radius:2px;*/
	background-color:#f36969;
	color:#ffffff;
	border:0px;
	-webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_newsletter_field button:hover{
	background:#ffffff;
	color:#000000;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
/*-- Newsletter css End --*/
/*-- jp footer Wrapper Start --*/
.jp_main_footer_img_wrapper{
    margin-top: 30px;
	/*background:url('../images/content/footer_bg.jpg') 50% 0 repeat-y;*/
	background-position:center 0;
	background-size:cover;
	background-repeat:no-repeat;
	float:left;
	width:100%;
	height:100%;
	position:relative;
	border-bottom:2px solid #23c0e9;
}
.jp_footer_logo_wrapper{
	float:left;
	width:100%;
	text-align:center;
	padding-bottom:50px;
	border-bottom: 1px solid #ffffff1a;
}
.jp_footer_three_sec_main_wrapper{
	float:left;
	width:100%;
	padding-top:80px;
}
.jp_footer_main_wrapper{
	float:left;
	width:100%;
	padding-top:50px;
}
.jp_footer_logo{
	display:inline-block;
}
.jp_footer_first_cont h2{
	font-size:20px;
	color:#ffffff;
	font-weight:900;
	text-transform:uppercase;
	position:relative;
}
.jp_footer_first_cont h2:after{
	content:'';
	border:1px solid #23c0e9;
	width:30px;
	position:absolute;
	bottom: -15px;
    left: 11px;
}
.jp_footer_first_cont h2:before{
	content:'';
	border:1px solid #23c0e9;
	width:8px;
	position:absolute;
	bottom: -15px;
    left: 0;
}
.jp_footer_first_cont p{
	padding-top:60px;
	color:#ffffffd9;
}
.jp_footer_first_cont ul{
	margin-top: 20px;
}
.jp_footer_first_cont li a{
	font-size:14px;
	font-weight:bold;
	color:#23c0e9;
	text-transform:uppercase;
}
.jp_footer_first_cont li i{
	color:#23c0e9;
	padding-right:5px;
}
.jp_footer_candidate h2{
	font-size:20px;
	color:#ffffff;
	font-weight:900;
	text-transform:uppercase;
	position:relative;
}
.jp_footer_candidate h2:after{
	content:'';
	border:1px solid #23c0e9;
	width:30px;
	position:absolute;
	bottom: -15px;
    left: 11px;
}
.jp_footer_candidate h2:before{
	content:'';
	border:1px solid #23c0e9;
	width:8px;
	position:absolute;
	bottom: -15px;
    left: 0;
}
.jp_footer_candidate ul{
	float:left;
	width:100%;
    padding:0px;
    margin: 60px 0px 0px;
}
.jp_footer_candidate ul li{
    list-style: none;
    float: left;
    width: 100%;
    padding: 0px 0px 15px 0px;
}
.jp_footer_candidate ul li a{
	float:left;
	width:auto;
	text-transform:capitalize;
	font-size: 16px;
	color:#ffffffd9;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_footer_candidate ul li a i{
	padding-right:10px;
	color:#f36969;
}
.jp_footer_candidate ul li a:hover{
	color:#f36969;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_bottom_footer_Wrapper{
	float:left;
	width:100%;
	border-top:1px solid #ffffff1a;
	margin-top:80px;
	padding-top:60px;
	padding-bottom:60px;
}
.jp_bottom_footer_left_cont p{
	color:#ffffff8c;
	margin-top: 12px;
}
.jp_bottom_footer_right_cont ul{
	float:right;
}
.jp_bottom_footer_right_cont li{
	float:left;
	margin-left:20px;
}
.jp_bottom_footer_right_cont li a{
	float:left;
	width:50px;
	height:50px;
	-webkit-border-radius:15px;
	-moz-border-radius:15px;
	border-radius:15px;
	background:transparent; 
	text-align:center;
	line-height:50px;
	color:#ffffff8c;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_bottom_footer_right_cont li a:hover{
	background:#23c0e9; 
	color:#ffffff;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
.jp_bottom_footer_left_cont{
	position:relative;
}
#return-to-top {
	position:absolute;
	float:left;
    bottom: 20px;
    right: -17px;
    top: 0;
    background: transparent;
	border:1px solid #ffffff2e;
    width: 52px;
    height: 50px;
    text-decoration: none;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
	z-index:1000;
}
#return-to-top i {
    color: #ffffff8c;
    margin: 0;
    position: relative;
    left: 16px;
    top: 8px;
    font-size: 30px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
#return-to-top:hover i {
    top: 5px;
	color:#ffffff;
}
#return-to-top:hover{
	background:#23c0e9;
	border:1px solid transparent;	
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
/*-- jp footer Wrapper End --*/

.jp_tittle_main_wrapper{
    background:url('../assets/img/tittle_bg.jpg') 50% 0 repeat-y;
    /*background-repeat:no-repeat;*/
    background-size:cover;
    /*background-position:center 0;*/
    position:relative;
    float:left;
    width:100%;
    padding-top:100px;
    padding-bottom:100px;
}

.jp_tittle_img_overlay{
    position:absolute;
    top:0;
    right:0;
    left:0;
    bottom:0;
    background:rgba(14, 20, 39, 0.8313725490196079);
}

.jp_tittle_heading{
    float:left;
    width:100%;
}
.jp_tittle_heading h2{
    font-size:36px;
    color:#ffffff;
    font-weight:bold;
}

.jp_tittle_breadcrumb_main_wrapper{
    float:left;
    width:100%;
    padding-top:25px;
}
.jp_tittle_breadcrumb_wrapper li{
    margin-left:20px;
    float:left;
}
.jp_tittle_breadcrumb_wrapper li a{
    color:#ffffffa6;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_tittle_breadcrumb_wrapper li a:hover{
    color:#23c0e9;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_tittle_breadcrumb_wrapper li:first-child{
    margin-left:0;
}
.jp_tittle_breadcrumb_wrapper li i{
    color:#23c0e9;
    position: relative;
    left: 10px;
}
.jp_tittle_breadcrumb_wrapper li:last-child{
    color:#23c0e9;
}

/* login_section start */

.login_section{
    width:100%;
    float:left;
}
.login_form_wrapper{
    float:left;
    width: 100%;
    padding-top:100px;
    padding-bottom:100px;
}
.login_form_wrapper h1{
    font-size:30px;
    text-align:center;
    font-weight:600;
    text-transform:uppercase;
    margin-bottom:30px;
    color:#ffffff;
}
.login_wrapper{
    float:left;
    width: 100%;
    background:#1a1e27;
    border: 1px solid #282d39;
    border-bottom: 1px solid #23c0e9;
    padding:50px;
    margin-bottom:20px;
}
.login_wrapper button.btn {
    color: #fff;
    width: 100%;
    height: 50px;
    padding: 6px 25px;
    line-height: 36px;
    margin-bottom:20px;
    text-align:left;
    border-radius:8px;
    background: #23c0e9;
    font-size: 16px;
    border: 1px solid #23c0e9;
}
.login_wrapper button.btn:hover {
    background-color: #00b5e4;
    border-color: #00b5e4;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.login_wrapper a.btn {
    color: #fff;
    width: 100%;
    height: 50px;
    padding: 6px 25px;
    line-height: 36px;
    margin-bottom:20px;
    text-align:left;
    border-radius:8px;
    background: #23c0e9;
    font-size: 16px;
    border: 1px solid #23c0e9;
}
.login_wrapper a.btn:hover {
    background-color: #00b5e4;
    border-color: #00b5e4;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.login_wrapper a span{
    float:left;
}
.login_wrapper a i {
    float: right;
    margin: 0;
    line-height: 35px;
}
.login_wrapper a.google-plus{
    background: #db4c3e;
    border: 1px solid #db4c3e;
}
.login_wrapper a.google-plus:hover{
    background: #bd4033;
    border-color:#bd4033;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.login_wrapper h2 {
    font-size: 18px;
    font-weight:500;
    margin-bottom:20px;
    color:#fff;
    line-height: 20px;
    text-transform: uppercase;
    text-align:center;
    position: relative;
}
.login_wrapper h2::before, .login_wrapper h2::after {
    content: "";
    background: rgba(255, 255, 255, 0.29);
    width: 100px;
    height: 1px;
    position: absolute;
    top: 50%;
}
.login_wrapper h2::before {
    right: 60%;
}
.login_wrapper h2::after {
    left: 60%;
}
.login_wrapper .form-control {
    height: 53px;
    padding: 15px 20px;
    font-size: 14px;
    line-height: 24px;
    border: 1px solid #282d39;
    border-radius: 8px;
    box-shadow: none;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    background-color: #1a1e27;
}
.form-group .field-icon{
    float: right;
    left: -15px;
    margin-top: -50px;
    position: relative;
    z-index: 2;
}
.login_wrapper textarea.form-control{
    height:auto;
    resize:none;
}
.login_wrapper input::placeholder, .login_wrapper textarea::placeholder {
    color: #999;
}
.login_wrapper .form-control:focus {
    color: #999;
    background-color: #fafafa;
    border: 1px solid #23c0e9 !important;
}
.login_wrapper  .formsix-pos,.formsix-e{
    position: relative;
}
.login_wrapper .form-group.i-password:after, .form-group.i-email:after{
    position: absolute;
    top: 13px;
    font-size: 16px;
    font-family: "FontAwesome";
    color: #c0c0c0;
}
.login_wrapper .form-group.i-email:after{
    content: "\f0e0";
    right: 25px;
}
.login_wrapper .form-group.i-password:after{
    content: "\f09c";
    right: 25px;
}
.login_remember_box{
    margin-top:30px;
    margin-bottom:30px;
    color:#999;
}
.login_remember_box .control {
    position: relative;
    padding-left:20px;
    cursor: pointer;
    font-size: 14px;
    line-height:14px;
    font-weight: 500;
    margin:0;
}
.login_remember_box .control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.login_remember_box .control__indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 13px;
    height: 13px;
    background: #fff;
    border: 1px solid #999;
}
.login_remember_box .control__indicator:after {
    content: '';
    position: absolute;
    display: none;
}
.login_remember_box .control input:checked ~ .control__indicator:after {
    display: block;
}
.login_remember_box .control--checkbox .control__indicator:after {
    left: 4px;
    top: 0;
    width: 5px;
    height: 10px;
    border: solid #111;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.login_remember_box .forget_password{
    float:right;
    color:#db4c3e;
    font-size:14px;
    text-decoration:underline;
}
.login_btn_wrapper{
    padding-bottom:20px;
    margin-bottom:30px;
    border-bottom:1px solid rgba(255, 255, 255, 0.29);
}
.login_btn_wrapper a.login_btn {
    text-align:center;
    text-transform:uppercase;
}
.login_btn_wrapper button.login_btn {
    text-align:center;
    text-transform:uppercase;
    width: 100%;
}
.login_btn_wrapper button.login_btn:hover{
    background-color: #00b5e4;
    border-color: #00b5e4;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.login_btn_wrapper a.login_btn:hover {
    background-color: #00b5e4;
    border-color: #00b5e4;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.login_btn_wrapper button.login_btn {
    text-align:center;
    text-transform:uppercase;
    width: 100%;
}
.login_btn_wrapper button.login_btn:hover{
    background-color: #00b5e4;
    border-color: #00b5e4;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.login_message p{
    text-align:center;
    font-size:16px !important;
    margin:0;
}
.login_message a{
    color:#23c0e9;
}
.login_form_wrapper p{
    width:70%;
    text-align:center;
    margin:0px auto;
    font-size:14px;
}

/* login_section end */

/* register_section start */

.register_section{
    float:left;
    width:100%;
    padding-top:100px;
    padding-bottom:100px;
}
.register_form_wrapper{
    float:left;
    width:100%;
}
.register-tabs.nav-justified>.active>a,
.register-tabs.nav-justified>.active>a:focus,
.register-tabs.nav-justified>.active>a:hover {
    border-bottom-color: #f1f1f1;
    background-color: #fff;
    color: #4285f4;
}
.register_tab_wrapper .register-tabs {
    font-size: 10px;
    border-bottom: none;
    padding-top:0;
    padding-bottom:0;
    text-transform: uppercase;
}
.custom_input input{
    margin: 20px 0;
}
.register_tab_wrapper .register-tabs>li.active {
    position: relative;
}
.register_tab_wrapper .register-tabs>li.active::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -5px;
    margin-left: -10px;
    border-top: 5px solid #23c0e9;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}
.register_tab_wrapper .register-tabs>li>a {
    line-height: 20px;
    padding: 15px 30px;
    border: 0;
    border-radius: 0px;
    background-color: #1a1e27;
    border:1px solid #282d39;
    border-bottom:0;
    color: #6f7e98;
    font-size:20px;
    text-transform:capitalize;
    transition: inherit;
    width:300px;
}
.register_tab_wrapper .register-tabs>li>a>span {
    font-size:14px;
    text-transform:capitalize;
    font-weight:400 !important;
}
.custom_input p{
    font-size:14px;
}
.register_tab_wrapper .register-tabs>li.active>a,
.register_tab_wrapper .register-tabs>li.active>a:focus,
.register_tab_wrapper .register-tabs>li.active>a:hover {
    color: #fff;
    background-color: #23c0e9;
    border: 0;
}
.register_tab_wrapper .tab-content{
    background-color: #1a1e27;
    padding: 30px;
    float:left;
    border: 1px solid #282d39;
    border-bottom: 1px solid #23c0e9;
    margin-bottom:30px;
    width:100%;
}
.register_tab_wrapper .nav > li{
    float:left;
}
.register_left_form{
    float:left;
    width:100%;
}
.register_left_form .column {
    padding-right: 30px;
}
.register_left_form .form-group {
    position: relative;
    margin-bottom: 5px;
}

.register_left_form .field-label i {
    float: right;
    font-size: 16px;
    color: #666;
}

.register_left_form .img-upload img{
    width: 180px;
    height: 180px;
}

.register_left_form .form-group .text {
    position: relative;
    color: #bbbbbb;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 5px;
}
.register_left_form input[type="text"],
.register_left_form input[type="email"],
.register_left_form input[type="password"],
.register_left_form input[type="tel"],
.register_left_form input[type="number"],
.register_left_form input[type="url"],
.register_left_form select,
.register_left_form textarea {
    position: relative;
    display: block;
    width: 100%;
    background: #1a1e27;
    text-transform:capitalize;
    font-size: 15px;
    line-height: 26px;
    color: #888888;
    padding: 12px 15px;
    border-radius:7px;
    height: 50px;
    margin-bottom: 15px;
    border: 1px solid #282d39;
    transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
}

.register_left_form textarea {
    resize: none;
    height: 60px !important;
    padding-left: 30px;
}

.register_left_form input:focus,
.register_left_form select:focus,
.register_left_form textarea:focus {
    border-color: #23c0e9;
}
.jp_regiter_top_heading p{
    font-size:16px;
    text-transform:capitalize;
    margin-bottom:20px;
    margin-top:10px;
}
.check-box{
    margin-top:35px;
}
.checkout-page .check-box {
    line-height: 24px;
    font-size: 14px;
    font-weight: normal;
    padding-top: 5px;
}
.check_box_anchr{
    color:#23c0e9 !important;;
}
.checkout-page .check-box label {
    position: relative;
    top: -1px;
    font-weight: normal;
    padding: 0px;
    font-size: 18px;
    cursor: pointer;
    color: #333333;
}
.register_left_form input[type="checkbox"] + label:before{
    display:none;
}
.register_btn_wrapper{
    padding-top:15px;
}
.btm_txt_register_form{
    width: 70%;
    text-align: center;
    margin: 0px auto;
    font-size: 14px;
}
/* register_section end */

.jp_cp_profile_main_wrapper{
    float:left;
    width:100%;
    padding-top:100px;
    padding-bottom:100px;
}
.jp_cp_left_side_wrapper{
    float:left;
    width:100%;
}
.jp_cp_left_pro_wallpaper{
    float:left;
    width:100%;
    text-align:center;
}
.jp_cp_left_pro_wallpaper img{
    width:100%;
}
.jp_cp_left_pro_wallpaper h2{
    font-size:30px;
    font-weight:600;
    color:#ffffff;
    padding:30px 0;
    text-align:center;
}
.jp_cp_left_pro_wallpaper p{
    font-size:18px;
    padding-top:10px;
    text-align:center;
}
.jp_cp_left_pro_wallpaper ul{
    display:inline-block;
}
.jp_cp_left_pro_wallpaper li{
    float:left;
    margin-right:10px;
}
.jp_cp_left_pro_wallpaper li a{
    float:left;
    width:50px;
    height:50px;
    line-height:50px;
    text-align:center;
    background:#eee;
    color:#000000;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.jp_cp_left_pro_wallpaper li a:hover{
    background:#23c0e9;
    color:#ffffff;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.jp_cp_rd_wrapper{
    float:left;
    width:100%;
}
.jp_cp_rd_wrapper ul{
    margin-top:30px;
}
.jp_cp_rd_wrapper li:first-child a{
    float:left;
    width:100%;
    height:50px;
    text-align:center;
    line-height:50px;
    background:#23c0e9;
    color:#ffffff;
    font-size:18px;
    font-weight:600;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.jp_cp_rd_wrapper li:first-child a:hover{
    background:#f53157;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.jp_cp_rd_wrapper li:last-child a{
    float:left;
    width:100%;
    height:50px;
    margin-top:20px;
    text-align:center;
    line-height:50px;
    background:#f53157;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    color:#ffffff;
    font-size:18px;
    font-weight:600;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.jp_cp_rd_wrapper li:last-child a:hover{
    background:#23c0e9;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.jp_cp_right_side_wrapper{
    float:left;
    width:100%;
}
.jp_cp_right_side_inner_wrapper{
    float:left;
    width:100%;
}
.jp_cp_right_side_inner_wrapper h2{
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
    position: relative;
}
.jp_cp_right_side_inner_wrapper h2:before {
    content: '';
    border: 1px solid #23c0e9;
    width: 8px;
    position: absolute;
    bottom: -15px;
    left: 0;
}
.jp_cp_right_side_inner_wrapper h2:after {
    content: '';
    border: 1px solid #23c0e9;
    width: 30px;
    position: absolute;
    bottom: -15px;
    left: 12px;
}
.jp_cp_right_side_inner_wrapper > table td {
    padding: 10px 30px 10px 0px;
    font-size: 18px;
    font-weight: 400;
    /*line-height: 40px;*/
}
.skills-selector{
    max-width: 480px;
}
.jp_cp_right_side_inner_wrapper > table{
    margin-top:30px;
}
.td-w65 {
    width: 65%;
    color: #6f7e98;
    font-style: italic;
}
.td-w25 { width: 25%; }
.td-w10 { width: 10%; }
.jp_cp_accor_heading_wrapper{
    float:left;
    width:100%;
    margin-top:60px;
}
.jp_cp_accor_heading_wrapper h2{
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
    position: relative;
}
.jp_cp_accor_heading_wrapper h2:before {
    content: '';
    border: 1px solid #23c0e9;
    width: 8px;
    position: absolute;
    bottom: -15px;
    left: 0;
}
.jp_cp_accor_heading_wrapper h2:after {
    content: '';
    border: 1px solid #23c0e9;
    width: 30px;
    position: absolute;
    bottom: -15px;
    left: 12px;
}
.jp_cp_accor_heading_wrapper p{
    padding-bottom:30px;
    font-size:16px;
    padding-top: 30px;
}
.jp_cp_left_ad_res{
    float:left;
    width:100%;
}


/*-- jp listing Single cont Wrapper Start --*/
.jp_listing_single_main_wrapper{
    float:left;
    width:100%;
    padding-top:60px;
    padding-bottom:60px;
}
.jp_listing_left_sidebar_wrapper{
    float:left;
    width:100%;
    background:#1a1e27;
    border:1px solid #282d39;
    padding:60px;
}
.jp_job_des{
    float:left;
    width:100%;
}
.jp_job_des h2{
    font-size:20px;
    font-weight:bold;
    color:#ffffff;
    text-transform:uppercase;
    position:relative;
}
.jp_job_des h2:after{
    content:'';
    border:1px solid #23c0e9;
    width:30px;
    position:absolute;
    bottom: -15px;
    left: 11px;
}
.jp_job_des h2:before{
    content:'';
    border:1px solid #23c0e9;
    width:8px;
    position:absolute;
    bottom: -15px;
    left: 0;
}
.jp_job_des p{
    padding-top:50px;
}
.jp_job_des ul{
    margin-top:20px;
}
.jp_job_des li{
    float:left;
    margin-left:20px;
}
.jp_job_des li:first-child{
    margin-left:0;
}
.jp_job_des li a{
    font-size:16px;
    color:#6f7e98;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_job_des li a:hover{
    color:#23c0e9;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_job_res{
    float:left;
    width:100%;
    padding-top:60px;
}
.jp_job_res li i{
    color:#ff5353;
}
.jp_job_res h2{
    font-size:20px;
    font-weight:bold;
    color:#ffffff;
    text-transform:uppercase;
    position:relative;
}
.jp_job_res h2:after{
    content:'';
    border:1px solid #23c0e9;
    width:30px;
    position:absolute;
    bottom: -15px;
    left: 11px;
}
.jp_job_res h2:before{
    content:'';
    border:1px solid #23c0e9;
    width:8px;
    position:absolute;
    bottom: -15px;
    left: 0;
}
.jp_job_res p{
    padding-top:50px;
}
.jp_job_res ul{
    margin-top:30px;
}
.jp_job_res li{
    margin-top:25px;
}
.jp_job_res li:first-child{
    margin-top:0;
}
.jp_job_qua ul{
    margin-top:50px;
}
.jp_job_apply{
    float:left;
    width:100%;
    padding-top:60px;
}
.jp_job_apply h2{
    font-size:20px;
    font-weight:bold;
    color:#ffffff;
    text-transform:uppercase;
    position:relative;
}
.jp_job_apply h2:after{
    content:'';
    border:1px solid #23c0e9;
    width:30px;
    position:absolute;
    bottom: -15px;
    left: 11px;
}
.jp_job_apply h2:before{
    content:'';
    border:1px solid #23c0e9;
    width:8px;
    position:absolute;
    bottom: -15px;
    left: 0;
}
.jp_job_apply p{
    padding-top:50px;
}
.jp_job_map{
    float:left;
    width:100%;
    padding-top:60px;
}
.jp_job_map h2{
    font-size:20px;
    font-weight:bold;
    color:#ffffff;
    text-transform:uppercase;
    position:relative;
}
.jp_job_map h2:after{
    content:'';
    border:1px solid #23c0e9;
    width:30px;
    position:absolute;
    bottom: -15px;
    left: 11px;
}
.jp_job_map h2:before{
    content:'';
    border:1px solid #23c0e9;
    width:8px;
    position:absolute;
    bottom: -15px;
    left: 0;
}
.jp_job_map p{
    padding-top:50px;
}

.jp_listing_left_bottom_sidebar_wrapper{
    float:left;
    width:100%;
    border:1px solid #282d39;
    padding: 60px;
    background:#1a1e27;
    border-top:0;
    padding-top:50px;
    padding-bottom:50px;
}
.jp_listing_left_bottom_sidebar_social_wrapper ul{

}
.jp_listing_left_bottom_sidebar_social_wrapper li{
    float:left;
    margin-left:10px;
}
.jp_listing_left_bottom_sidebar_social_wrapper li:first-child{
    margin-left:0;
    color:#6f7e98;
    font-weight:bold;
    margin-top: 12px;
}
.jp_listing_left_bottom_sidebar_social_wrapper li a{
    float:left;
    width:50px;
    height:50px;
    -webkit-border-radius:20px;
    -moz-border-radius:20px;
    border-radius:20px;
    background:transparent;
    text-align:center;
    line-height:50px;
    color:#6f7e98;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_listing_left_bottom_sidebar_social_wrapper li a:hover{
    background:#23c0e9;
    color:#ffffff;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_listing_left_bottom_sidebar_key_wrapper{
    float:left;
    width:100%;
    padding:60px;
    padding-top:20px;
    padding-bottom:20px;
    border:1px solid #282d39;
    background:transparent;
    border-top:0;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_listing_left_bottom_sidebar_key_wrapper li{
    float:left;
    margin-left:20px;
}
.jp_listing_left_bottom_sidebar_key_wrapper li:first-child{
    margin-left:0;
    color:#6f7e98;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_listing_left_bottom_sidebar_key_wrapper li i{
    padding-right:5px;
    color:#23c0e9;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_listing_left_bottom_sidebar_key_wrapper li a{
    color:#6f7e98;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_listing_related_heading_wrapper{
    float:left;
    width:100%;
    margin-top:60px;
}
.jp_listing_related_slider_wrapper{
    float:left;
    width:100%;
    margin-top: 50px;
}
.jp_listing_related_slider_wrapper  .owl-theme .owl-dots{
    display:none;
}
.jp_listing_related_slider_wrapper .owl-theme .owl-nav {
    position: absolute;
    top: -74px;
    margin: 0;
    left: 0;
    right: 0;
    z-index:1000;
    display:block;
}
.jp_listing_related_slider_wrapper .owl-theme .owl-nav .owl-prev{
    font-size: 12px;
    float:left;
    width:60px;
    height:30px;
    line-height:30px;
    background:#1a1e27;
    margin: 0;
    padding: 0;
    right: 75px;
    top:0;
    color:#ffffff;
    -webkit-border-radius:10px;
    -moz-border-radius:10px;
    border-radius:10px;
    border:1px solid #282d39;
    position: absolute;
    text-align: center;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_listing_related_slider_wrapper  .owl-theme .owl-nav .owl-next{
    font-size: 12px;
    float:left;
    width:60px;
    height:30px;
    line-height:30px;
    margin: 0;
    padding: 0;
    right: 0;
    top:0;
    right:0;
    position: absolute;
    color:#ffffff;
    background:#1a1e27;
    -webkit-border-radius:10px;
    -moz-border-radius:10px;
    border-radius:10px;
    border:1px solid #282d39;
    text-align: center;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_listing_related_slider_wrapper .owl-theme .owl-nav .owl-prev:hover, .jp_listing_related_slider_wrapper  .owl-theme .owl-nav .owl-next:hover{
    background:#23c0e9;
    border:1px solid #23c0e9;
    color:#ffffff;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_listing_related_heading_wrapper h2{
    font-size:20px;
    color:#ffffff;
    font-weight:bold;
    position:relative;
    text-transform:uppercase;
}
.jp_listing_related_heading_wrapper h2:after{
    content:'';
    border:1px solid #23c0e9;
    width:30px;
    position:absolute;
    bottom: -15px;
    left: 11px;
}
.jp_listing_related_heading_wrapper h2:before{
    content:'';
    border:1px solid #23c0e9;
    width:8px;
    position:absolute;
    bottom: -15px;
    left: 0;
}
.jp_rightside_listing_single_wrapper{
    margin-top:0;
}
.jp_jop_overview_img_wrapper{
    text-align:center;
    background:#1a1e27;
    border-left:1px solid #282d39;
    border-right:1px solid #282d39;
}
.jp_jop_overview_img{
    display:inline-block;
    padding-top:30px;
}
.jp_rightside_listing_single_wrapper{
    float:left;
    width:100%;
    /*background:#ffffff;*/
}
.jp_job_listing_single_post_right_cont{
    float:left;
    width:100%;
    text-align:center;
    background-color: #1a1e27;
    border: 1px solid #282d39;
    padding-bottom: 30px;
}
.jp_job_listing_single_post_right_cont_wrapper{
    display:inline-block;
}
.jp_job_listing_single_post_right_cont_wrapper h4{
    font-size:16px;
    color:#ffffff;
    font-weight:bold;
    padding-top:40px;
}
.jp_job_listing_single_post_right_cont_wrapper p{
    font-size:16px;
    color:#23c0e9;
    padding-top:10px;
}
.jp_job_post_right_overview_btn_wrapper{
    text-align:center;
    padding-bottom:50px;
    background:#1a1e27;
    border-bottom:1px solid #282d39;
    border-left:1px solid #282d39;
    border-right:1px solid #282d39;
}
.jp_job_post_right_overview_btn ul{
    display:inline-block;
    margin-top: 15px;
}
.jp_job_post_right_overview_btn_wrapper .jp_job_post_right_overview_btn ul li{
    float:left;
    margin-left:20px;
}
.jp_job_post_right_overview_btn_wrapper .jp_job_post_right_overview_btn ul li:first-child {
    margin-left:0;
}
.jp_job_post_right_overview_btn_wrapper .jp_job_post_right_overview_btn ul li:first-child button{
    float:left;
    /*width:30px;*/
    /*height:30px;*/
    border:1px solid #282d39;
    text-align:center;
    line-height:30px;
    -webkit-border-radius:10px;
    -moz-border-radius:10px;
    border-radius:10px;
    color:#f36969;
    background:transparent;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_job_post_right_overview_btn_wrapper .jp_job_post_right_overview_btn ul li:first-child button:hover{
    background:#f36969;
    color:#ffffff;
    border:1px solid #f36969;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}

.jp_job_post_right_overview_btn_wrapper .jp_job_post_right_overview_btn ul li:first-child button.liked_project{
    background:#f36969;
    color:#ffffff;
    border:1px solid #f36969;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.jp_job_post_right_overview_btn_wrapper .jp_job_post_right_overview_btn ul li:first-child button.liked_project:hover{
    background:transparent;
    color:#f36969;
    border:1px solid #282d39;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}

.jp_job_post_right_overview_btn li:last-child{
    /*float:none;*/
    margin:0;
}
.jp_job_post_right_overview_btn li:last-child p{
    float:left;
    width:130px;
    /*height:30px;*/
    /*line-height:30px;*/
    padding: 2px 5px;
    text-align:center;
    background:#37d09c;
    color:#ffffff;
    font-size:12px;
    text-transform:uppercase;
    -webkit-border-radius:10px;
    -moz-border-radius:10px;
    border-radius:10px;
}
.jp_listing_overview_list_main_wrapper{
    float:left;
    width:100%;
}
.jp_listing_overview_list_main_wrapper2{
    padding-top:40px;
}
.jp_listing_overview_list_outside_main_wrapper{
    float:left;
    width:100%;
    padding:50px;
    background:#1a1e27;
    border:1px solid #282d39;
    border-top:0;
}
.jp_listing_list_icon{
    float:left;
    width:20px;
}
.jp_listing_list_icon_cont_wrapper{
    float:left;
    width:calc(100% - 20px);
    padding-left:10px;
}
.jp_listing_list_icon_cont_wrapper ul{

}
.jp_listing_list_icon i{
    color:#ff5353;
}
.jp_listing_list_icon_cont_wrapper li:first-child{
    font-weight:bold;
    color:#ffffff;
}
.jp_listing_right_bar_btn_wrapper{
    float:left;
    width:100%;
    text-align:center;
}
.jp_listing_right_bar_btn ul{
    display:inline-block;
    margin-top:40px;
}
/*-------------------------------------------*/
/*.jp_listing_right_bar_btn li:first-child button{*/
/*    float:left;*/
/*    width:230px;*/
/*    height:50px;*/
/*    text-align:center;*/
/*    line-height:50px;*/
/*    color:#ffffff;*/
/*    text-transform:uppercase;*/
/*    font-weight:bold;*/
/*    background:#23c0e9;*/
/*    -webkit-border-radius: 10px;*/
/*    -moz-border-radius: 10px;*/
/*    border-radius: 10px;*/
/*    -webkit-transition: all 0.5s;*/
/*    -o-transition: all 0.5s;*/
/*    -ms-transition: all 0.5s;*/
/*    -moz-transition: all 0.5s;*/
/*    transition: all 0.5s;*/
/*}*/
/*.jp_listing_right_bar_btn li:first-child button:hover{*/
/*    background:#f36964;*/
/*    -webkit-transition: all 0.5s;*/
/*    -o-transition: all 0.5s;*/
/*    -ms-transition: all 0.5s;*/
/*    -moz-transition: all 0.5s;*/
/*    transition: all 0.5s;*/
/*}*/
/*.jp_listing_right_bar_btn li:last-child{*/
/*    margin-top:70px;*/
/*}*/
/*.jp_listing_right_bar_btn li:last-child button{*/
/*    float:left;*/
/*    width:230px;*/
/*    height:50px;*/
/*    text-align:center;*/
/*    line-height:50px;*/
/*    color:#ffffff;*/
/*    text-transform:uppercase;*/
/*    font-weight:bold;*/
/*    background:#ff5353;*/
/*    -webkit-border-radius: 10px;*/
/*    -moz-border-radius: 10px;*/
/*    border-radius: 10px;*/
/*    -webkit-transition: all 0.5s;*/
/*    -o-transition: all 0.5s;*/
/*    -ms-transition: all 0.5s;*/
/*    -moz-transition: all 0.5s;*/
/*    transition: all 0.5s;*/
/*}*/
/*.jp_listing_right_bar_btn li:last-child button:hover{*/
/*    background:#23c0e9;*/
/*    -webkit-transition: all 0.5s;*/
/*    -o-transition: all 0.5s;*/
/*    -ms-transition: all 0.5s;*/
/*    -moz-transition: all 0.5s;*/
/*    transition: all 0.5s;*/
/*}*/

.isApply_btn_true {
    border: none;
    width:230px;
    height:50px;
    text-align:center;
    line-height:50px;
    color:#ffffff;
    text-transform:uppercase;
    font-weight:bold;
    background:#ff5353;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.isApply_btn_false {
    border: none;
    width:230px;
    height:50px;
    text-align:center;
    line-height:50px;
    color:#ffffff;
    text-transform:uppercase;
    font-weight:bold;
    background:#23c0e9;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}

.isApply_btn_false:hover{
    background:#ff5353;
}

.isApply_btn_true:hover{
    background:#23c0e9;
}
/*-- jp listing Single cont Wrapper End --*/
/************************* ---- Listing Single CSS End---- *************************/

/* 404 error wrapper start*/

.error_page{
    width:100%;
    float:left;
    padding-top:60px;
    padding-bottom:100px;
}
.error_page_cntnt{
    float:left;
    width:100%;
}
.error_page_cntnt span{
    font-size:200px;
    color:#23c0e9;
    font-weight:900;
}
.error_page_cntnt h2 span:first-child{
    text-shadow: -15px 0px 5px #000000;
    position: relative;
    z-index: 1;
}
.error_page_cntnt h2 span:nth-child(2){
    color: #fff;
    margin-left: -24px;
    z-index: 1;
    position: relative;
}
.error_page_cntnt h2 span:last-child{
    margin-left: -30px;
    z-index: 1;
    text-shadow: 7px 10px 5px #000000;
    position: relative;
}
.error_page_cntnt h3{
    font-size:30px;
    color:#fff;
    font-weight:500;
    text-transform:uppercase;
    position:relative;
    margin-bottom:40px;
}
.error_page_cntnt h3:after{
    content: '';
    border: 1px solid #23c0e9;
    border-radius: 5px;
    display: block;
    width: 110px;
    position: absolute;
    margin-top: -4px;
    margin-bottom: 10px;
    left: 0;
    right: 0;
    margin: 0px auto;
    bottom: -15px;
}
.error_page_cntnt p{
    font-size:16px;
    margin-bottom:30px;
    width:80%;
    margin:0px auto;
}
.error_page_cntnt a{
    color:#23c0e9;
}
.error_page_mail_wrapper input {
    width: 60%;
    height: 46px;
    position:relative;
    color: rgb(101, 101, 101);
    font-size: 16px;
    margin-top:30px;
    background: #fafafa;
    border: 1px solid #fafafa ;
    padding: 17px 30px 17px 14px;
    border-radius:15px;
    padding-left:40px;
}
.error_page_mail_wrapper input:focus{
    outline: none;
}
.error_page_mail_wrapper i {
    position: relative;
    left: 29px;
    z-index: 1;
    top: 0px;
    color: #23c0e9;
}

/*404 error wrapper end */
/* --- USER IMAGE comp start--- */
.img-wrapper{
    position: relative;
}
.img-wrapper:hover .image-btn-group{
    display: flex;
}

.image-btn-group{
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: none;
}
.delete-img-btn{
    margin-left: 10px;
    width: 38px;
    height: 38px;
}

/* --- USER IMAGE comp ens--- */